@import "./../../Parents.module.scss";

@mixin familyDiv() {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

.familyPlusSection {
  margin: -18em 20px 5em;
  color: white;
  .familyTitle {
    max-width: 980px;
    margin: 0em auto;
    padding: 2em 20px;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      h1 {
        font-size: 40px;
        line-height: 35px;
      }

      img {
        width: 75px;
        margin-right: 1em;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 26px;
      line-height: 35px;
      color: white;
      max-width: 750px;
      margin: 1.5em auto;
    }
  }

  .gridContainer {
    max-width: 980px;
    margin: 0em auto;
    padding-bottom: 2em;
    display: grid;
    grid-template-columns: repeat(2, minmax(350px, 1fr));
    grid-template-rows: repeat(2, 180px);
    gap: 10px 10px;
    grid-auto-flow: row;
  }

  .familyFeature {
    @include familyDiv();
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 0.75em 1.5em;
    text-align: left;
    overflow: hidden;
    position: relative;

    .featureHeader {
      display: flex;
      align-items: center;
      h3 {
        font-size: 26px;
      }
      .image {
        margin-right: 1.5em;
        span {
          width: 100%;
          img {
            width: auto;
          }
        }
      }
    }
    p {
      margin: 0;
      line-height: 22px;
      font-size: 16px;
    }

    &.big {
      grid-column: 1;
      grid-row: 1 / span 2;
      padding-top: 1.5em;
      p {
        margin-top: 2em;
      }
      &:after {
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        background: rgba(255, 255, 255, 0.2);
        bottom: -145px;
        right: -130px;
        border-radius: 100%;
        z-index: 1;
      }
    }
  }

  .orangeBtn {
    @include btn(white, white, 0em);
    background: linear-gradient(94.11deg, #f26283 0.33%, #fe875f 100.5%);
    width: 200px;
    margin-top: 2em;
    z-index: 2;
    border-color: $main-pink;
    margin-bottom: 2em;
  }

  .hauntContainer {
    display: flex;
    justify-content: space-between;
    max-width: 980px;
    margin: 0em auto;
    padding: 4em 20px;
    flex-wrap: wrap;
    .hauntFigure {
      max-width: 380px;
      img {
        // width: 380px;
        height: auto;
      }
    }
    .hauntText {
      max-width: 475px;
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }
}
.blueContainer {
  background: linear-gradient(101.43deg, #0ca7d8 8.78%, #2e66ae 97.04%);
  border-radius: 10px;
  padding-top: 6em;
}

.hauntToolsContainer {
  display: flex;
  justify-content: space-between;
  max-width: 1100px;
  margin: 0 auto;
  padding-bottom: 2em;
  flex-wrap: wrap;
  .hauntTool {
    @include familyDiv();
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.5em;
    margin: 5px;
    flex: 1;
    text-align: left;
    width: 340px;
    .image {
      width: 90px;
      margin-right: 10px;
      img {
        width: 90px;
      }
    }
    .toolBody {
      max-width: 200px;
      min-width: 100px;
      h4 {
        font-size: 20px;
        line-height: 31px;
      }
      p {
        margin-top: 0.25em;
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
}

.strip {
  position: relative;
  overflow: hidden;
  margin: 0 auto -4em;
  padding: 2em 3em 4em;
  max-width: 980px;
  color: $main-blue;
  background-color: white;
  border-radius: 20px;
  text-align: left;
  box-shadow: -5px -5px 25px rgba(6, 95, 229, 0.2), 5px 5px 25px rgba(6, 95, 229, 0.2);
  h1 {
    max-width: 665px;
    font-size: 40px;
    line-height: 40px;
  }
  .logoDeco {
    bottom: -40px;
    right: 100px;
    width: 160px;
    position: absolute;
    transform: rotate(-15deg);
  }
}

.beeContainer {
  max-width: 800px;
  margin: 1em auto;
  .beeHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      font-size: 35px;
      // line-height: 54px;
    }
    img {
      width: 78px;
      margin-right: 20px;
    }
  }
  p {
    max-width: 720px;
    margin: 10px;
    font-size: 18px;
    line-height: 24px;
  }

  .beeStepContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .beeStep {
      &:nth-child(2) {
        span {
          font-size: 24px;
        }
        &:after {
          content: "2";
        }
      }
      &:nth-child(3) {
        &:after {
          content: "3";
        }
      }
      box-sizing: border-box;
      overflow: hidden;
      position: relative;
      background-color: $main-grey--bee;
      background-image: url("./../../../../Assets/WritingBee/wordsBg.png");
      background-size: cover;
      width: 380px;
      height: 105px;
      margin: 10px;
      padding: 1.5em;
      border-radius: 20px;
      text-align: left;
      display: flex;
      align-items: center;
      &:after {
        position: absolute;
        width: 160px;
        height: 160px;
        bottom: -70px;
        right: -80px;
        border-radius: 100%;
        content: "1";
        font-size: 60px;
        background-color: $main-orange--bright;
        box-sizing: border-box;
        padding-left: 35px;
      }

      span {
        display: block;
        width: 280px;
        font-size: 18px;
        line-height: 20px;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 1180px) {
  .strip {
    margin-top: 20em;
  }
}

@media (max-width: 950px) {
  .familyPlusSection {
    .hauntContainer {
      justify-content: center;
    }
  }
  .strip {
    text-align: center;
    overflow: visible;
    h1 {
      font-size: 30px;
    }
    .logoDeco {
      top: -5em;
      width: 50px;
      transform: rotate(15deg);
    }
  }
}

@media (max-width: 850px) {
  .familyPlusSection {
    .familyTitle {
      .title {
        h1 {
          font-size: 32px;
        }
        img {
          width: 60px;
          height: 60px;
        }
      }
      h2 {
        font-size: 22px;
      }
    }
    .gridContainer {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      .familyFeature {
        h3 {
          font-size: 18px;
        }
        &.big {
          p {
            margin-top: 1em;
          }
        }
      }
    }
  }
}

@media (max-width: 520px) {
  .strip {
    padding: 2em 1em 1em;
    h1 {
      font-size: 24px;
    }
  }
}
