@import "./../../../shared.scss";

.bgColor {
    background-color: $main-blue;
    color: white;
    
    &.bee {
        background-color: $main-grey--bee;
        .footerContainer {
            .mainFooter {
                .socialContainer {
                    display: flex;
                    flex-direction: column;
                    .direction{
                        font-size: 12px;
                        text-align: left;
                    }

                    .socialMedia {
                        display: flex;

                        .socialLink {
                            height: 14px;
                            width: 14px;
                            background-color: $main-orange--bright;
                        }
                    }
                }
            }
        }
    }

    &.faithJournals {
        background-color: #442f0f;
        .footerContainer {
            padding: 2.5em 0em 0.25em;
            flex-direction: column;
            align-items: center;

            .faithJournalsDiv{
                position: relative;
                top:5px;
                height: 120px;
                width: 300px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                img{
                    width: 120px;
                    height: 35px;
                    margin-bottom: 20px;
                }
                div.contactUs{
                    @include solidButton(white, $main-yellow, 18px, 35px, 140px);
                }
            }
            .infoText{
                margin-top: 30px;
            }
        }
    }
}

.bgColorModal {
    background-color: $main-blue;
    color: white;
    position: absolute;
    bottom: 0;
}

.footerContainer {
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    padding: 3em 0em 0.25em;

    &.mini {
        padding: 0.5em 2em 0.25em;
    }

    .mainFooter {
        display: flex;
        justify-content: space-around;
        width: 100%;
        flex-wrap: wrap;

        .socialContainer {
            display: flex;
            flex-direction: column;
            .direction{
                font-size: 12px;
                line-height: 18px;
                text-align: left;
                margin:10px 4px  4px;
            }

            .socialMedia {
                display: flex;

                .socialLink {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 24px;
                    height: 24px;
                    background-color: $main-blue--bright;
                    border-radius: 100px;
                    margin: 0.5em 0.15em;

                    img {
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }

        .linksContainer {
            display: flex;
            flex-wrap: wrap;

            .linkGroup {
                display: flex;
                flex-direction: column;
                text-align: left;

                margin: 0 1em;

                .title {
                    text-align: left;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    margin-bottom: 0.5em;
                    color: white;
                }

                a {
                    font-size: 14px;
                    line-height: 19px;
                    margin: 0.25em 0em;

                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }
    }

    .infoText {
        color: rgba(255, 255, 255, 0.5);
        margin-top: 3em;
        text-align: center;

        &.mini {
            margin-top: 0.5em;
        }

        p {
            font-size: 12px;
            line-height: 16px;
        }
    }
}

@media (max-width: 900px) {
    .footerContainer {
        padding: 3em 3em 0.25em;

        .mainFooter {
            justify-content: flex-start;

            .linksContainer {
                .linkGroup {
                    margin: 0 2em 0 0;
                    width: 105px;

                    .title {
                        margin: 1em 0em 0.5em;
                    }
                }
            }
        }
    }
}

@media (max-width: 450px) {
  .footerContainer {
    padding: 3em 3em 0.25em;

    * {
      text-align: center !important;
    }

    .linksContainer {
      display: none !important;
    }

    .mainFooter {
      justify-content: center;

      .socialContainer {
        .socialMedia {
          justify-content: center;
        }

        .direction {
          line-height: 15px;
        }
      }
    }

    .infoText{
      margin-top: 20px;
    }
  }
}

@media (max-height: 600px) {
    .footerContainer {
        .infoText {
            &.mini {
                margin-top: 0;
            }

            p {
                font-size: 8px;
            }
        }

        .mainFooter {
            .socialContainer {
                .socialMedia {
                    margin-top: 5px;

                    .socialLink {
                        margin: 0 2px;
                    }
                }
            }
        }
    }
}
