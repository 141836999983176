@import './../../../shared.scss';

.trustContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 12em;
    color: $main-blue;
    padding-bottom: 3em;
    // flex-wrap: wrap;

    z-index: 4;

    .text {
        text-align: left;
        width: 300px;
        margin-bottom: 2em;

        h2 {
            font-size: 35px;
            line-height: 40px;
        }

        a {
            @include btn(white, $main-blue--bright, 1.5em)
        }
    }

    .carousel {

        .sliderButtons {
            display: flex;
            flex-wrap: wrap;

            .slider {
                width: 580px;
                border-radius: 20px;
                -webkit-box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
                -moz-box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
                box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
                background-color: white;
            }

            .buttons {

                display: flex;
                // width: 100%;
                justify-content: space-evenly;
                margin-left: 1em;
            }
        }

    }
}

.carouselContainer {
    width: 100%;
}

.centerCarousel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.slider {
    width: 100%;
    height: 500px;
    // padding: 1em;
}

@media (max-width:1200px) {

    .trustContainer {
        color: $main-blue;
        flex-wrap: wrap;
        margin-top: 0em;
        padding-top: 2em;
        background-color: $main-lightbluegrey;


        .text {
            text-align: center;
            margin: 0.5em 1em;
            width: 80%;
        }
    }

    .slider {
        padding: 2em;
        // margin-left: 4em;
    }


}