@import "./../../../../shared.scss";

.banner {
  width: 1180px;
  height: 364px;
  background-color: $main-grey--bee;
  background-image: url("./../../../../Assets/WritingBee/wordsBg.png");
  background-size: cover;
  // background: url("./../../../../Assets/Holidays/HolidayBg-tinified.png");
  position: fixed;
  box-shadow: -5px -5px 25px rgba(6, 95, 229, 0.2), 5px 5px 25px rgba(6, 95, 229, 0.2);
  border-radius: 20px 0px 0px 20px;
  top: 30vh;
  right: 0;
  z-index: 30;
  transition: all 1.1s cubic-bezier(0.52, -0.56, 0.52, 1.27);
  overflow: hidden;
  &.close {
    width: 100px;
  }

  .bannerBody {
    position: absolute;
    color: white;
    width: 1180px;
    opacity: 1;
    transition: opacity 1.5s ease;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 2;
    .content {
      padding-top: 2em;
    }
    &.close {
      opacity: 0;
    }
    h3 {
      font-size:30px;
      line-height: 40px;
      display: flex;
      align-items: center;
      max-width: 600px;
      margin-top: 5px;
    }
    p {
      max-width: 500px;
    }
    .blackBtn {
      @include btn(white, $main-black, 1.5em);
      position: absolute;
      bottom:20px;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.25));
    }
  }

  .closeBtn{
    position: absolute;
    top:10px;
    left:10px;
    height: 30px;
    width: 30px;
    object-fit: contain;
  }
  .world {
    position: absolute;
    width: 1300px;
    height: 50%;
    bottom: 0;
    background-image: url("./../../../../Assets/BeeBanner/world.png");
    background-repeat: repeat-x;
    background-size: cover;
    z-index: -1;
  }

  .closedTitle {
    color: white;
    font-size: 20px;
    line-height: 25px;
    font-weight: bold;
    top: calc((100% - 230px) / 2);
    padding-left: 2em;
    position: absolute;
    left: 65px;
    opacity: 1;
    transition: opacity 1.5s ease;
    z-index: 2;
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    -moz-writing-mode: vertical-rl;
    -ms-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 20px;
    &.dissapear {
      opacity: 0;
    }

    &.families{
      top: 20px;
      font-size: 18px !important; 
      
    }
  }

  .openBtn {
    cursor: pointer;
    height: 100%;
    position: absolute;
    left: -90px;
    width: 4px;
    // background-color: blue;
    padding: 0 4em;
    z-index: 3;
    &:after {
      content: " ";
      // left: 15px;
      width: 3px;
      height: 100px;
      top: calc((100% - 100px) / 2);
      background-color: $main-orange--bright;
      z-index: 1;
      right: 25px;
      position: absolute;
      border-radius: 2px;
    }
  }

  .bigImg {
    width: 250px;
    position: absolute;
    opacity: 1;
    top: 20px;
    &.left {
      left: 50px;
    }
    &.right {
      right: 50px;
    }
  }

  .deco {
    position: absolute;
    opacity: 1;
    transition: opacity 1.5s ease;
    z-index: 1;
    &.topLeft {
      width: 300px;
      transform: rotate(-90deg);
      top: 160px;
      left: -105px;

      &.dissapear {
        opacity: 0;
      }
    }
  }
}

@media (max-width: 1200px) {
  .banner {
    width: 350px;

    .bannerBody {
      width: 350px;
      text-align: left;
      .content {
        margin-left: 2.5em;
        span {
          width: 300px;
          margin-left: -2em;
        }
        h3 {
          width: 250px;
          font-size: 32px;
          line-height: 40px;

          padding: 0.5em 0
        }
      }

      .blackBtn {
        @include btn(white, $main-orange--bright, 1.5em);

        margin-top: 0;
      }
    }
    .world {
      display: none;
    }

    // .openBtn {
    //   left: -15px;
    //   width: 3px;
    //   &:after {
    //     width: 3px;
    //   }
    // }
    .bigImg {
      display: none;
    }
  }
}

@media (max-width: 660px) {
  .banner {
    display: none;
  }
}
