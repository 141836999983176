@import "./../../WritingBee.module.scss";

.phases {
  margin: 5em auto;
  background-color: $main-yellow;
  background-image: linear-gradient(rgba(255, 168, 32, 0.9), rgba(255, 168, 32, 0.9)),
    url("./../../../../Assets/WritingBee/phasesBg.png");
  flex-direction: column;
  padding: 3em;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  h3 {
    font-size: 35px;
    line-height: 35px;
    padding: 1em;
  }
  p {
    line-height: 26px;
  }

  h3,
  p {
    max-width: 650px;
  }
  img:first-child {
    max-width: 500px;
  }
  .phaseDivContainer {
    display: flex;
    .phaseDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      &:nth-child(3) {
        figure {
          img {
            width: 80%;
          }
        }
      }
      h4 {
        font-size: 25px;
        line-height: 34px;
      }
      figure {
        margin: 0;
        background-color: white;
        border-radius: 40px;
        height: 60px;
        width: 60px;
        padding: 1em;
        margin: 2em 3em 1em;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        margin: 0em;
      }
    }
  }
}

@media (max-width: 1320px) {
  .phases {
    margin: 5em 2em;
  }
}

@media (max-width: 950px) {
  .phases {
    .phaseDivContainer {
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 520px) {
  .phases {
    padding: 2em 1em;
    h3{
      padding: 1em 0;
      font-size: 25px;
    }
  }
}

@media (max-width: 400px) {
  .phases {
    h3{
      padding: 1em 0;
      font-size: 20px;
    }
    p{
      padding: 0;
      font-size: 14px;
    }
  }
}
