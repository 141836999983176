@import '../../../../shared.scss';

.container{

  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: initial !important;
  box-sizing:border-box;
  padding:30px 150px;



    .loadingOverlay{
      min-height: 100%;
      min-width: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top:0;
      left: 0;
      z-index: 100;
      pointer-events: none;

      img{
        height: 100px;
        width: 100px;
        object-fit: contain;
      }
      p{
        position: relative;
        bottom:10px;
        font-size: 17px;
        color:$main-blue;
      }
  }

  .topBanner{
    border-radius: 15px;
    height: 40%;
    background: rgb(7,124,160);
    background: linear-gradient(124deg, rgba(7,124,160,1) 0%, rgba(43,78,123,1) 78%);
    box-sizing:border-box;
    box-sizing:border-box;
    padding:60px 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    .content{
      height: 130px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      z-index: 10;

      .input{
        display: flex;
        align-items: center;
        height: 40px;
        position: relative;

        input[type='text']{
          height: 40px;
          width: 400px;
          border-radius: 5px;
          background-color: rgba(255, 255, 255, 0.097);
          border:1px solid rgba(255, 255, 255, 0.227);
          color:white;
          box-sizing:border-box;
          padding:0 20px;
          font-size: 16px;

        }

        .purchaseBtn{
          @include solidButton(white, $main-yellow, 16px, 40px, 150px);
          margin-left: 5px;
          font-weight: 700;
          border-radius: 5px;
        }

        .error{
          color:#d00000;
          position: absolute;
          top:45px;
          left:05px;
          font-size: 15px;
        }
      }

    }

    .books{
      position: absolute;
      right: -20px;
      bottom: 0;
      overflow: hidden;
      width: 400px;
      z-index: 1;

      .bookCover{
        width: 160px;
        position: relative;
        bottom: -50px;
        transform: rotate(-15deg);

        &:last-of-type{
          left: -30px;
        }
      }

      @media (max-width:1450px){
        right: -70px;

        .bookCover{
          width:130px
        }
      }
    }

    @media (max-width:1200px){
      background:linear-gradient(124deg, rgba(7,124,160,1) 0%, rgba(43,78,123,1) 78%);
        
      h1{
        font-size: 30px;
      }

      .content{
        width: 100%;

        .input{
          input{
            width:100%;
          }
        }
      }

      .books{
        display: none;
      }
    }

  }



  .bottom{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: calc(60% - 40px);
    
    .bubble{
      width: calc(50% - 20px);
      border-radius: 15px;
      height: 310px;
      text-align:left;
      box-sizing:border-box;
      padding:30px 40px;
      
      cursor: pointer;
      transition: all .1s ease-in;
      position: relative;
      bottom:0;
  
      &:hover{
        bottom: 3px;
      }

      p{
        font-size:16px;
        width: 80%;
      }


      *{
        color:white
      }

      &.dark{
        background:url('../../../../Assets/Bookstore/store-main--2.png') no-repeat right bottom;
        background-color: $main-blue;
        // background-size: 90%;
      }
      &.light{
        background:url('../../../../Assets/Bookstore/store-main--3.png') no-repeat center bottom;
        background-color: $main-blue--light;
        background-size: 90%;
        
    
      }
    }
  }
  

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba(255, 255, 255, 0.55);
      font-size: 14px;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba(255, 255, 255, 0.55);
      font-size: 14px;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: rgba(255, 255, 255, 0.55);
      font-size: 14px;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: rgba(255, 255, 255, 0.55);
      font-size: 14px;
    }

}