@import '../../../shared.scss';

.container{
  display: flex;
  align-items: center;
  justify-content: flex-end;

  p{
    font-size: 16px;
    color: $main-blue;
    font-weight: 500;
    width: fit-content;
    height: 25px;
    line-height: 25px;
    margin-right: 20px;

    &.logBtn{
      cursor: pointer;
      width: 70px;

      &:hover{
        text-decoration: underline;
      }
    }
  }

  .blueBtn{
    @include solidButton(white, $main-blue--bright, 16px, 35px, 120px)
  } 
}