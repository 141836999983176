@import '../../../shared.scss';


.carouselBtn {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    border: none;

    // margin: 0.5em;

    img {
        width: 32px;
    }

    &.back {
        margin-right: 0.5em;
        background-color: $main-blue;
    }

    &.next {
        margin-left: 0.5em;
        background-color: white;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        -moz-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);

    }

}

.carouselBtn:focus {
    outline: none;
}