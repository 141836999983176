@import "./../../WritingBee.module.scss";

.authorCarrouselContainer {
  margin-top: 10em;
  .authorSlide {
    display: flex;
    position: relative;
    width: 1100px;
    height: 520;
    justify-content: flex-end;
    text-align: left;
    border-radius: 20px;
    z-index: 1;
    margin-bottom: 100px;
    .copyright {
      position: absolute;
      z-index: 2;
      bottom: 50px;
      right: 10px;
      color: white;
      font-size: 10px;
      font-weight: bold;
    }
    .slideBody {
      max-width: 500px;
      color: white;
      z-index: 2;
      padding: 3em;
      p {
        font-size: 14px;
      }
      h3 {
        font-size: 34px;
        line-height: 45px;
      }
      .orangeBtn {
        @include btn(white, $main-orange--bright, 3em);
      }
    }

    span {
      //imgs are spans
      z-index: 2;
    }

    .writingBeeLogo {
      position: absolute;
      top: 20px;
      left: 10px;
      z-index: 2;
    }
    .bigKinney {
      left: 30px;
      bottom: -11px;
      position: absolute;
    }
    .bigCraft {
      left: 100px;
      bottom: -11px;
      position: absolute;
    }
    .kinneyDeco {
      position: absolute;
      bottom: -100px;
      right: 70px;
      animation: float 2s linear infinite;
    }
    .craftDeco {
      position: absolute;
      bottom: -80px;
      right: 50px;
      animation: updown 2s linear infinite;
    }

    .pseudo {
      // This is for handling the after and make the overposition, it doesnt work other way
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      border: 5px solid $main-blue;

      background: linear-gradient(270deg, #2b4e7b 0%, rgba(43, 78, 123, 0.77) 100%),
        url("./../../../../Assets/WritingBee/kinneybg.png");

      &:after {
        position: absolute;
        border-radius: 20px;
        content: "";
        bottom: -32px;
        right: -32px;
        width: 100%;
        height: 100%;
        z-index: -1;

        background-color: #a0d1f1;
        border: 5px solid $main-blue;
      }
      &.craft {
        border: 5px solid #575757;
        background: linear-gradient(270deg, #575757 0%, rgba(87, 87, 87, 0.77) 100%),
          url("./../../../../Assets/WritingBee/craftbg.png");
        &:after {
          background-color: #fff30e;
          border: 5px solid #575757;
        }
      }
    }
  }
  .orangeBottomBtn{
    @include btn(white,$main-orange--bright,3em);
    margin:5em
  }
}

@media (max-width: 950px) {
  .authorCarrouselContainer {
    display: none;
  }
}

@keyframes float {
  from {
    transform: rotate(15deg) translate3d(8px, 0, 0) rotate(15deg);
  }

  to {
    transform: rotate(375deg) translate3d(8px, 0, 0) rotate(-345deg);
  }
}

@keyframes updown {
  0% {
    transform: translateY(10px);
  }
  25% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  75% {
    transform: translateY(0px);
  }

  100%{
    transform: translateY(10px);
  }

  
}
