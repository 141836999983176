@import './../../shared.scss';

.bullet {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 48%;
    height: 150px;

    img {
        width: 140px;
    }

    p {
        box-sizing: border-box;
        padding: 15px;
        height: 100%;
        width: 70%;
        text-align: left;
        color: white
    }
}

@media(max-width: 1050px) {
    .bullet {
        flex-direction: column;
        height: fit-content;
        width: fit-content;

        img {
            width: 120px;
        }

        p {
            width: fit-content;
            font-size: 16px;
            line-height: 16px;
            padding: 0;
            margin-top: 0;
            text-align: center;
            height: fit-content;
        }
    }
}