@import './../../../shared.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 12em;
    color: $main-blue;
    padding-bottom: 15em;
    width: 100vw;
    z-index: 4;

    @media (max-width:1250px){
      max-width: 100vw;
      width: 95vw;
    }

    .text {
        text-align: center;
        width: 100%;
        margin-bottom: 2em;

        h2 {
            color: $main-blue;
            font-size: 38px;
            line-height: 50px;

            @media (max-width:1100px){
              width: 80%;
              margin:0 auto;
            }
        }

        a {
            @include btn(white, $main-blue--bright, 1.5em)
        }
    }

    .carousel {
      // border:1px solid chartreuse;

        .sliderButtons {
            display: flex;
            align-items: center;
            flex-direction: column;
            flex-wrap: wrap;

            @media (max-width:1250px){
              width: 95%;
            }

            .slider {
                width: 100%;
                height: 380px;
                border-radius: 20px;

                .card{
                  height: 380px;
                  width: 100%;
                  border-radius: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  box-sizing:border-box;
                  padding:50px 80px;

                  @media (max-width:1250px){
                    width: 90%;
                    margin:0 auto;

                    img.cardImg{
                      max-width: 220px;
                    }
                  
                  }

                  .text{
                    height: 100%;
                    width: 600px;
                    // border:1px solid chartreuse;
                    .numberCircle{
                      margin-bottom: 15px;
                      color:#077ca0;
                      width: 45px;
                      height: 45px;
                      line-height: 45px;
                      line-height: 45px;
                      text-align: center;
                      font-size: 30px;
                      font-weight: 900;
                      border-radius: 50%;
                      background-color: white;
                    }

                    h3{
                      color:white;
                    }
                  }

                }

                img.cardImg{
                  max-width: 320px;
                  height: 320px;
                  object-fit: contain;
                }
            }

            .buttons {
              // border:1px solid chartreuse;
                width: 200px;
                display: flex;
                justify-content: space-evenly;
                margin-left: 1em;
                margin-top: 30px;
            }
        }

    }
}

.carouselContainer {
    width: 100%;
}

.centerCarousel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.slider {
    width: 100%;
    height: 500px;
    // padding: 1em;
}

@media (max-width:1200px) {

    .trustContainer {
        color: $main-blue;
        flex-wrap: wrap;
        margin-top: 0em;
        padding-top: 2em;
        background-color: $main-lightbluegrey;


        .text {
            text-align: center;
            margin: 0.5em 1em;
            width: 80%;
        }
    }

    .slider {
        padding: 2em;
        // margin-left: 4em;
    }


}