@import '../../../../shared.scss';

.container{
    background-color: $main-grey--bee;
    background-image: url("./../../../../Assets/BeeWinners/background_clouds.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    padding-top: 3em;
    position: relative;
    width: 100vw;
    min-height: calc(100vh - 250px);
    max-width: initial;
    padding: 30px 0 !important;
    // border:1px solid red;

    .inner{
        display: flex;
        align-items: center;
        height: 100%;
        width: 100vw;
        min-height: calc(100vh - 150px);
        max-width: 1200px;
        margin: 0 auto;
        box-sizing:border-box;
        padding:20px;
        // border:1px solid red;
    }

    .left,
    .right{
        min-height: calc(100vh - 150px);
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // border:1px solid chartreuse;
    }

    .left{



        .boomers,
        .mainText{
            // border:1px solid red;
            position: relative;
            bottom:20px;

            &.res{
                display: none;
            }

            @media (min-height:800px){
                // border:1px solid chartreuse;
                position: relative;
                bottom:5vh;
            }
        }
        

        .boomers{
            display: flex;
            width: 350px;
            justify-content: space-between;
            position: relative;
            // top: 65px !important;
            // left:-20px;
            // border:1px solid chartreuse;
            // border:1px solid red;

            .winnersTextRes{
                display: none;
            }

            .boomerChapterWinner{
                height: 200px;
                width: auto;
            }
        }
    }

    .right{
        justify-content: center;
    }

    .boomerWinner{
        max-width: 480px;
        position: relative;
        bottom: -10px;

        &.res{
            display: none;
        }

        @media (min-height:800px){
            bottom: 0px;
        }
    }

    @media (max-width:1024px){

        .mainText.init{
            display: none;
        }
        .mainText.res{
            display: initial !important;
            width: 80vw;
            position: relative;
            left: 10px;
        }

        background-size: cover;
        .inner{
            .right{
                display: none;
            }

            .left{
                width: 100%;

                .boomerWinner.res{
                    display: initial;
                    width: 70vw;
                    position: relative;
                    left: 20px;
                    bottom: 30px;
                }

                .boomers {
                    position: relative;
                    top: 30px;
                    
                    .winnersTextRes{
                    width: 500px;
                    position: absolute;
                    top:-40px;
                    left: -70px;
                    display: initial;
                    color: #333333;
                    font-weight: 700;
                    font-size: 16px;
                    font-style: italic;
                    font-family: 'Sofia Sans', sans-serif;
                    }
                }
            }
        }
    }

    @media (max-width:600px){

        .left{
            .boomerWinner{
                bottom: 0px;
            }
        }

        .boomers{
            box-sizing:border-box;
            padding:30px 0 0 0;
        }
        p{
            white-space: initial;
            width: 70vw !important;
            left:50% !important;
            transform: translateX(-50%);
        }
    }


}