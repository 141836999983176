@import '../../../shared.scss';


.tooltip {
    box-shadow: 0px 0px 4px 0 rgba(180, 207, 247, 1);
    background-color: white;
    text-align: left;
    width: 250px;
    padding: 0.5em 1em;
    display: none;
    position: absolute;
    z-index: 4;
    top: 100px;
    border-radius: 10px;

    &.btn {
        top: 91%;
        left: 50px;
    }


    h3 {
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        padding-top: 0.5em;
        color: #333333;

    }


    .littleP {

        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        /* or 114% */


        color: #858585;
    }

    &.show {
        display: block;
    }
}



.tooltip:before {
    content: '';
    // box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.14), -2px -2px 4px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 0px;
    right: calc(50% - 14px);
    width: 0;
    height: 0;
    box-sizing: border-box;

    border: 5px solid black;
    border-color: transparent transparent white white;

    transform-origin: 0 0;
    transform: rotate(135deg);

    box-shadow: -2px 2px 4px 0 rgba(180, 207, 247, 1);
}