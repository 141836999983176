@import "../../../shared.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

$white: #fff;

$ease: ease;
$duration: 600ms;

.navContainer {
  position: relative;
  display: flex;
  align-items: center;
  height: 80px;
  background-color: transparent;
  z-index: 30;
  margin-bottom: -80px;
  // width: 100%;
  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 30px -15px rgba(40, 78, 123, 1);
    animation: moveDown 0.5s ease-in-out;
    .login {
      color: $main-blue;
    }
  }

  &.home{
    // border:1px solid chartreuse;
    background-color: transparentx;
    // padding-top: 10px;

    .webinarsBar{
      height: 35px;
      line-height: 35px;
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(12,167,216);
      background: linear-gradient(124deg, rgba(12,167,216,1) 0%, rgba(46,102,174,1) 78%);
      cursor: pointer;

      h6{
        font-size: 13px;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        color: white;

        span{
          margin-left: 10px;
          color: $main-yellow;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .navbarList{
      li{
        color: white;

        &:hover{
          color: #ffa826;
        }
      }
    }
    
    &.sticky{
      padding-top: 0;
      height: 50px;
      background-color: white;
      
  
      .navbarList{
        li{
          color: $main-blue;
          &:hover{
            color:$main-blue--bright;
          }
        }
      }
      .webinarsBar{
        display: none;
      }
    }

    @media (max-width:950px){
      padding-top: 0;
    
    }
  }

  @keyframes moveDown {
    from {
      transform: translateY(-5rem);
    }
    to {
      transform: translateY(0rem);
    }
  }

  .logo {
    width: 120px;
    height: 30px;
    margin: 1em 1.5em;
    z-index: 5;
  }

  .whiteLogo {
    width: 200px;
    height: 50px;
    background: url("../../../Assets/Nav/whiteLogo.png") center no-repeat;
    background-size: 120px 20px;
    z-index: 5;
  }

  .navbarList {
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    list-style: none;
    flex-wrap: wrap;

    li {
      padding: 1em 1em;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      color: $main-blue;

      &:hover {
        color: $main-blue--bright; // text-decoration: underline;
      }
    }
  }

  .activeNav li {
    color: $main-green;
    text-decoration: underline;
  }
}

.navBtns {
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}


.login {
  color: white;
  font-size: 16px;
  margin-right: 4em;
  font-weight: bold;

  cursor: pointer;
}

.loginClear {
  @include btn(white, $main-blue--bright, 3em);
  font-size: 14px;
}

.loginGeneral {
  color: $main-blue;
  font-size: 16px;
  font-weight: 700;
  margin-right: 4em;
  cursor: pointer;
}

.signUp {
  @include btn(white, $main-orange--bright, 2em);
  padding: 10px 25px;
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }
}

.responsiveMenu {
  display: none;
  position: absolute;
  top: -2px;

  left: calc(100% - 50px);

  .burgerWrapper {
    height: 50px;
    width: 50px;
    background-image: url("./../../../Assets/Nav/movileDecoration.svg");
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;

    &.orange {
      background-image: url("./../../../Assets/Nav/movileDecorationActive.svg");
    }

    .hamburgerToClose {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -15px;
      margin-left: -15px;
      width: 20px;
      height: 20px;
      cursor: pointer;

      &:before,
      &:after {
        content: "";
      }

      .hamburgerMiddle,
      &:before,
      &:after {
        position: absolute;
        width: 20px;
        height: 2px;
        background-color: white;
        border-radius: 5px;
      }

      .hamburgerMiddle {
        top: 50%;
        left: 50%;
        margin-top: 1px;
        transition: transform $ease ($duration/2);
      }

      &:before {
        top: 6px;
        transition: top ($duration/2) $ease ($duration/2), transform $ease ($duration/2);
      }

      &:after {
        bottom: 2px;
        transition: bottom ($duration/2) $ease ($duration/2), transform $ease ($duration/2);
      }

      &.close {
        .hamburgerMiddle {
          transform: rotate(-45deg);
          transition: transform ($duration/2) $ease ($duration/2);
        }

        &:before {
          top: 11px;
          transform: rotate(-45deg);
          transition: top $ease ($duration/2), transform ($duration/2) $ease ($duration/2);
        }

        &:after {
          bottom: 8px;
          transform: rotate(45deg);
          transition: bottom $ease ($duration/2), transform ($duration/2) $ease ($duration/2);
        }
      }
    }
  }
}

.modalMenu {
  display: flex;
  position: fixed;
  width: 100%;
  height: 0;
  top: 0;
  right: 0;
  color: white;
  font-size: 18px;
  line-height: 16px;
  font-weight: bold;
  background: linear-gradient(11.41deg, #077ca0 32.92%, #2b4e7b 83.21%);
  transition: all 1s ease;
  z-index: 5;

  &.show {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0px;
    z-index: 15;

    .linkContainer {
      opacity: 1;
    }
  }

  .linkContainer {
    display: none;

    opacity: 0;
    margin-right: auto;
    margin-left: 2.5em;
    margin-top: 5em;
    text-align: left;
    transition: all 1s ease-out;

    &.show {
      display: block;
    }

    .normalA {
      cursor: pointer;
      display: block;
      padding: 1.25em 0em 0.75em;
    }
  }
}

@media (max-width: 1350px) {

  .navContainer {
    .logo{
      margin: 0.5em 0.5em 0.5em 20px;
    }
    .navbarList{
      li {
        font-size: 14px;
      }
    }
  }
  .signUp {
    font-size: 14px;
  }
  .login {
    font-size: 14px;
  }
}

@media (max-width: 1240px) {
  .navContainer {
    .navbarList {
      li {
        padding: 0 0.75em;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 1090px) {
  .login {
    margin-right: 1em;
  }
}

@media (max-width: 1020px) {
  .navBtns {
    padding: 0 20px;
  }
  .navContainer {
    .navbarList {
      li {
        font-size: 12px;
      }
    }
  }
  .signUp {
    font-size: 12px;
  }
  .login {
    font-size: 12px;
  }
}

@media (max-width: 950px) {
  .navContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    margin-bottom: -50px;

    &.sticky {
      // position: absolute;
      animation: none;
      &.show {
        background-color: transparent;
      }
    }
    .navbarList {
      display: none;
    }

    .navBtns {
      display: none;
    }

    .responsiveMenu {
      display: block;
    }
  }
}

@media (max-height: 700px) {
  .navContainer {
    height: 50px;
  }

  .modalMenu {
    .linkContainer {
      .normalA {
        display: block;
        padding: 0.75em 0em;
      }
    }
  }
}

@media (max-height: 550px) {
  .modalMenu {
    .linkContainer {
      margin-top: 2em;
    }
  }
}

// }
