@import "../../shared.scss";

.hero {
    background-image: url("./../../Assets/School/bg.png");
    background-repeat: no-repeat;
    height: 810px;
    width: 100%;
    background-position: bottom;
    background-size: cover;
    position: relative;
    color: white;
    overflow: hidden;

    .heroText {
        min-width: 650px;
        max-width: 1150px;
        margin: 0 auto 30px auto;
        padding-top: 100px;
        text-align: center;

        button {
            @include btn(white, $main-orange--bright, 3em);
            margin: 1em auto 0;
        }

        h1 {
            font-size: 45px;
            line-height: 55px;
            width: 85%;
            margin: 0 auto;
        }

        h2 {
            color: white;
            font-size: 25px;
            line-height: 33px;
            font-weight: 300;
            width: 50%;
            margin: 10px auto 1em auto;
        }

        a {
            @include btn(white, $main-orange--bright, 3em);
        }

        .screensDecoration {
            background-image: url("./../../Assets/School/screens2.png");
            background-size: contain;
            background-position-y: bottom;
            background-position-x: center;
            background-repeat: no-repeat;
            max-width: 1400px;
            min-width: 600px;
            height: 440px;
            position: absolute;
            bottom: -95px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }
}

.consistency {
    // padding-top: 5em;
    padding: 5em 0em;

    &.cappedHeight {
        height: 400px;
        padding: 3em 0em;
        margin-bottom: 10em;
        // margin-top: 10em;
    }
}

.container {
    justify-content: center;
    align-items: center;
}

.half {
    img {
        max-width: 450px;
        margin: 0 5em;
    }
}

.text {
    max-width: 400px;
    text-align: left;
    margin: 0 5em;
    h3 {
        font-size: 36px;
        line-height: 33px;
        color: $main-blue;
    }

    p {
        font-size: 16px;
        line-height: 28px;
        color: $main-blue;
    }

    a {
        @include btn(white, $main-blue--bright, 3em);
        &.orange {
            @include btn(white, $main-orange--bright, 3em);
            padding: 0.5em 3em;
        }
    }

    button {
        @include btn(white, $main-blue--bright, 3em);
        // margin: 1em auto
    }
}

.blueContainer {
    display: flex;
    flex-direction: column;
}

.featureContainer {
    margin: 0 auto;
    max-width: 1200px;
    padding-bottom: 3em;
}

.teamTeaching {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3em;
    p {
        color: $main-blue;
    }
    .teamTeachingCardsContainer {
        display: flex;
        margin: 3em 0em;
        
        .teamTeachingCard {
            padding: 4em 2em;
            margin: 10px;
            background-color: white;
            border-radius: 10px;
            @include shadow(30px,rgba(6, 95, 229, 0.2));
            max-width: 310px;
            color: $main-blue;
            text-align: left;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 160px;
                margin-bottom: 2em;
                &.rotate{
                    transform: rotate(-45deg);
                }
            }
            h4{
                font-size: 26px;
                height: 60px;
                width: 100%;
                display: flex;
                align-items: center;
            }
            ul{
                padding-left:5px
            }
        }
    }
}

.bubbles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 140px;
    width: 1100px;
    margin: 4em auto 0;

    .bubble {
        height: 100%;
        width: 30%;
        margin: 0 10px;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: 100%;

        &:hover {
            background-position: 0 -133px;
        }

        &.active {
            background-position: 0 -266px;
        }

        &.schools {
            background-image: url("./../../Assets/School/bubble-schools--sprite.png");
        }

        &.families {
            background-image: url("./../../Assets/School/bubble-family--sprite.png");
        }

        &.students {
            background-image: url("./../../Assets/School/bubble-students--sprite.png");
        }
    }
}

.bubblesMobile {
    display: none;
    display: flex;
    flex-direction: column;
    margin: 4em auto 0;

    .bubbleMobile {
        display: none;
        width: 85%;
        height: 105px;
        margin: 3px auto;

        background-repeat: no-repeat;
        background-position: center center;
        /* background-size: contain; */

        &.schools {
            background-image: url("./../../Assets/School/bubble-mobile-school-unselected.jpg");

            &.active {
                background-image: url("./../../Assets/School/bubble-mobile-school-selected.jpg");
            }
        }

        &.families {
            background-image: url("./../../Assets/School/bubble-mobile-family-unselected.jpg");

            &.active {
                background-image: url("./../../Assets/School/bubble-mobile-family-selected.jpg");
            }
        }

        &.students {
            background-image: url("./../../Assets/School/bubble-mobile-student-unselected.jpg");

            &.active {
                background-image: url("./../../Assets/School/bubble-mobile-student-selected.jpg");
            }
        }
    }
}

.bulletDisplay {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px auto 40px auto;
    background-color: rgba(82, 108, 142, 0.57);
    height: 0px;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    padding: 1.5em 0em;
    transition: all 1s ease;

    &.responsive {
        height: 0;
        padding: 0;
        flex-wrap: wrap;
        margin: 0px;
    }

    &.desktop {
        display: flex;
        height: 490px;
    }
}

.ourTools {
    display: flex;
    flex-direction: column;
    align-content: center;

    .toolsHeader {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 5em;

        img {
            width: 180px;
        }

        .toolText {
            margin-left: 3em;

            h3 {
                font-size: 40px;
            }
        }
    }

    .cardContainer {
        display: flex;
        justify-content: center;
        // flex-wrap: wrap;

        .tool {
            // background-color: #ededed;
            background-color: $main-lightbluegrey;

            padding: 1.5em;
            margin: 0 5px;
            border-radius: 25px;
            width: 27%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: left;
            position: relative;

            h4 {
                margin: 1em 0;
                font-size: 28px;
            }

            p {
                font-size: 14px;
                line-height: 27px;
                color: rgba(0, 0, 0, 0.5);
                padding: 0 25px 65px 25px;
            }

            .imgWrapper {
                width: 98%;
                height: 250px;
                background-color: white;
                border-radius: 25px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    height: 175px;
                    width: auto;

                    &.adjust {
                        height: 130px;
                    }
                }
            }

            &:before {
                content: "";
                width: 55%;
                height: 10px;
                left: 22.5%;
                top: 0;
                position: absolute;
                border-radius: 0px 0px 20px 20px;
            }

            &.assignments {
                &:before {
                    background-color: $main-orange--bright;
                }
            }

            &.journals {
                &:before {
                    background-color: $main-blue--light;
                }
            }

            &.stories {
                &:before {
                    background-color: $main-orange;
                }
            }
        }
    }
}

.benefitsFeatures {
    display: flex;
    flex-direction: column;
    padding-bottom: 10em;

    h3 {
        font-size: 40px;
        color: rgba(0, 0, 0, 0.8);
        margin: 0 0 1em;
    }

    .split {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 2em auto;
        width: 100%;
        height: 900px;

        h4 {
            box-sizing: border-box;
            padding-left: 40px;
            font-size: 30px;
            margin: 0 0 30px 25px;
        }

        .half {
            height: 100%;
            width: 47%;
            display: flex;
            flex-direction: column;
            justify-content: initial;
            align-items: flex-start;
            margin: 0em auto;
            vertical-align: middle;

            .example {
                align-self: flex-end;
                margin-top: 5em;

                &.left {
                    align-self: flex-start;
                }

                &.responsive {
                    display: none;
                }
            }

            .class {
                margin-left: 4em;
            }
        }
    }

    .featuresSheet {
        height: fit-content;
        width: 98%;
        box-sizing: border-box;
        padding: 30px 55px;
        border-radius: 10px;
        background-color: white;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 6px 9px 17px 5px rgba(0, 0, 0, 0.05);

        p {
            font-size: 14px;
            line-height: 27px;
            color: black;
            box-sizing: border-box;
            margin-bottom: 30px;
            border-radius: 25px;
        }

        ul {
            list-style-image: url("./../../Assets/School/ulDecoration.svg");
            padding-left: 20px;
            position: relative;

            li {
                font-size: 12px;
                line-height: 22px;
                margin-bottom: 0.5rem;

                .itemBold {
                    font-size: 13px;
                    font-weight: bold;
                }
            }
        }

        .contactBtn {
            @include btn(white, $main-orange--bright, 2.5em);
            margin: 0 auto;
        }

        a {
            @include btn(white, $main-orange--bright, 2.5em);
            margin: 0 auto;
        }
    }
}

.proDev {
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    align-items: center;
    text-align: center;
    padding: 3.5em 9em;
    // box-shadow: 0px 0px 20px 0px rgba(180, 207, 247, 1);
    box-shadow: 6px 9px 17px 5px rgba(0, 0, 0, 0.15);
    margin: 0 auto;

    &.text {
        max-width: 700px;
    }
}

.priSafSec {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .textContainer {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 0 1em 2em;

        .imgWrapper {
            width: 48%;
            min-width: 400px;

            p{
                color:$main-blue;
                font-size: 16px;
                line-height: 25px;
                width: 85%;
                margin: 0 auto;
                position: relative;
                bottom:15px;
            }
        }
    }

    .logoContainer {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 1em;

        a {
            background: none;
            box-shadow: none;
            color: none;
            text-align: center;
            padding: 0;
            margin: 0;
            border: none;
            transition: all 0.5s ease;

            &:hover {
                background-color: none;
                color: none;
                border: none;
            }
        }

        .logo {
            height: 100px;
            width: auto;

            &.horizontal {
                height: 60px;
            }
        }
    }
}

.contact {
    background-image: url("./../../Assets/School/contactBg.png");
    background-repeat: no-repeat;
    background-position: center center;
    // background-size: contain;
    width: 75%;
    padding: 1.5em;
    border-radius: 20px;
    display: flex;

    align-items: flex-start;

    .contactText {
        color: White;
        margin-left: 5em;
        margin-top: 5em;

        p {
            color: White;
        }
    }

    .formPanel {
        background-color: white;
        border-radius: 10px;
        padding: 10px 20px;
        margin-left: auto;
        width: 35%;
        min-width: 400px;

        form {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            height: fit-content;

            .inputDiv {
                width: 49%;
                box-sizing: border-box;
                text-align: left;

                label {
                    display: block;
                    font-weight: 700;
                    margin: 10px 0 5px 10px;
                    font-size: 12px;
                    color: black;
                }

                input {
                    height: 35px;
                    font-family: "Sofia Sans", sans-serif;
                    font-size: 12px;
                    border: 1px solid rgba(128, 128, 128, 0.2);
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    width: 100%;
                    border-radius: 5px;
                    background-color: #dfe5e7;
                    box-sizing: border-box;
                    padding-left: 15px;
                }

                textarea {
                    border: 1px solid rgba(128, 128, 128, 0.2);
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    border-radius: 5px;
                    background-color: #dfe5e7;
                    width: 100%;
                    height: 80px;
                    resize: none;
                }

                &.complete {
                    width: 100%;
                }
            }

            a {
                @include btn(white, $main-orange--bright, 8em);
                margin: 1em auto;
            }

            button {
                @include btn(white, $main-orange--bright, 8em);
                margin: 1em auto;
            }
        }
    }
}

@media (max-width: 1250px) {
    .bulletDisplay {
        margin: 0 40px;
    }
}

@media (max-width: 1100px) {
    .bubbles {
        display: none;
    }

    .bubblesMobile {
        display: flex;

        .bubbleMobile {
            display: block;
        }
    }

    .bulletDisplay {
        &.desktop {
            display: none;
        }

        &.active {
            // &.responsive {
            //     height: 1550px;
            //     width: auto;
            //     padding: 1.5em 1em;

            // }
            margin: 0 40px;
            height: 1050px;
            width: auto;
            padding: 1.5em 1em;
            display: flex;
        }
    }

    .benefitsFeatures {
        .split {
            padding-bottom: 10em;
        }
    }
}

@media (max-width: 1050px) {
    .bulletDisplay {
        &.active {
            height: 1200px;
        }
    }

    .consistency {
        &.cappedHeight {
            height: fit-content;
            padding: 3em 0em;
            margin-bottom: 0em;
        }
    }
    .half {
        img {
            margin: 0 2em;
        }
    }

    .text {
        margin: 0 2em;
    }

    .proDev {
        padding: 3.5em 3em;

        box-shadow: 6px 9px 17px 5px rgba(0, 0, 0, 0.15);
        max-width: 1000px;

        &.text {
            max-width: 700px;
            margin: 0 auto;
        }
    }
}

@media (max-width: 860px) {
    .hero {
        .heroText {
            min-width: 400px;

            h1 {
                font-size: 40px;
                line-height: 45px;
            }

            h2 {
                font-size: 20px;
                line-height: 26px;
                width: 70%;
            }

            .screensDecoration {
                background-image: url("./../../Assets/School/screens.png");
                min-width: 400px;
                bottom: -50px;
            }
        }
    }
    .teamTeaching {
        
        .teamTeachingCardsContainer {
            flex-wrap: wrap;
            .teamTeachingCard {
                max-width: 100%;
                width: 90%;
                h4{
                    justify-content: center;
                }
            }
        }
    }

    .cycle {
        flex-wrap: wrap;
        text-align: center;
        margin: 1em;

        .text {
            text-align: center;
        }
    }

    .ourTools {
        .toolsHeader {
            .toolText {
                margin: 2em 0;
            }
        }

        .cardContainer {
            .tool {
                padding: 1.5em;
                margin: 1em 0px;
                width: 100%;
            }
        }
    }

    .benefitsFeatures {
        padding-bottom: 0em;

        h3 {
            font-size: 35px;
        }

        .split {
            flex-direction: column;
            margin: 0em auto 2em;
            height: fit-content;
            padding-bottom: 0em;

            h4 {
                box-sizing: border-box;
                margin: 1em auto;
            }

            .half {
                height: fit-content;
                width: 90%;

                .example {
                    align-self: center;
                    margin-top: 0em;
                    display: none;

                    &.responsive {
                        display: block;
                    }
                }

                .class {
                    display: none;
                }
            }
        }

        .featuresSheet {
            padding: 1em;
        }
    }

    .proDev {
        margin: 1em;
    }

    .priSafSec {
        .textContainer {
            justify-content: center;
        }

        .logoContainer {
            .logo {
                margin: 1em 0em;
            }
        }
    }

    .contact {
        background-size: cover;
        height: fit-content;
        width: auto;

        .contactText {
            margin: 0;
        }

        .formPanel {
            width: 100%;
            min-width: auto;

            .inputDiv {
                width: 100%;
            }
        }
    }
}

@media (max-width: 500px) {
    .bulletDisplay {
        &.active {
            height: 1500px;
        }
    }

    .contact {
        .formPanel {
            form {
                .inputDiv {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 420px) {
    .hero {
        height: 700px;

        .heroText {
            min-width: 300px;

            h1 {
                font-size: 33px;
                line-height: 30px;
            }

            h2 {
                font-size: 18px;
                line-height: 20px;
                width: 70%;
            }

            .screensDecoration {
                bottom: -40px;
                min-width: 300px;
            }
        }
    }

    .ourTools {
        .cardContainer {
            .tool {
                padding: 1.5em;
                margin: 1em 0px;
                width: 100%;

                p {
                    padding: 0 25px 60px 25px;
                }

                .imgWrapper {
                    width: 98%;
                    height: 250px;
                    background-color: white;
                    border-radius: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        height: 145px;
                        width: auto;

                        &.adjust {
                            height: 110px;
                        }
                    }
                }
            }
        }
    }

    .proDev {
        margin: 1em;
        padding: 1.5em 2em;
    }

    .priSafSec {
        .textContainer {
            .imgWrapper {
                min-width: 300px;
            }
        }
    }
}
