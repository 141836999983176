@import '../../../shared.scss';

.mapContainer {

    position: relative;
    margin: 100px 0;
    
    h2 {
        position: relative;
        top:60px;
        z-index: 10;
        color: $main-blue;
        font-size: 45px;
        line-height: 50px;
        width: 600px;
        margin:0 auto;

        @media (max-width:1100px){
            margin:0 auto;
            width: 60%;
        }
    }


    .world{
        position: relative;
        z-index: 10;
    }

}


.bgCropper{
    height: 1800px;
    width: 6000px;
    border-radius: 50%;
    background-color: white;
    border-bottom: 3px solid #cddffa;
    position: absolute;
    left: 50%;
    bottom: -120px;
    transform: translateX(-50%);
    overflow: hidden;
    z-index: 1;

    img.boomer_girl{
      height: 220px;
      object-fit: contain;
      position: absolute;
      bottom: -21px;
      right: 42%;
      transform: translateX(50%);
      z-index: 10;
  
      @media (max-width:1250px){
        right: 42%;
      }
      @media (max-width:1100px){
        right: 43%;
      
      }
      @media (max-width:960px){
        display: none;
      
      }
    }
  
    img.boomer_boy{
      height: 260px;
      object-fit: contain;
      position: absolute;
      bottom: -15px;
      left: 41%;
      transform: translateX(-50%);
      z-index: 10;
  
      @media (max-width:1250px){
        left: 42%;
      
      }
      @media (max-width:1100px){
        left: 43%;
      
      }
      @media (max-width:960px){
        display: none;
      
      }
    }
    .gradient{
      position: absolute;
      width: 5500px;
      left: 0;
      bottom: 0;
      height: 250px;
      opacity: .08;
      background: rgb(0,0,0);
      background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(43,78,123,1) 78%);
      z-index: 0;
    }

  }


.mobileMapContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main-lightbluegrey;


    .mobileBg {
        background-image: url('./../../../Assets/HomePage/worldMobile.svg');
        background-repeat: no-repeat;
        background-position: center;
        max-width: 350px;
        padding: 3em;
    }

    h2 {
        display: block;

        span {
            display: inline-block;
            font-size: 35px;
            line-height: 32px;
            padding: 0.4em;
            margin: 0.5em 0;
            background-color: white;
            max-width: 72px;
            border-radius: 20px;
            -webkit-box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
            -moz-box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
            box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
        }
    }
}