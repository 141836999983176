@import './../../../../shared.scss';

.featureCard {
    width: 150px;
    height: 150px;
    -webkit-box-shadow: 0px 0px 20px -5px rgba(5, 95, 230, 0.5);
    -moz-box-shadow: 0px 0px 20px -5px rgba(5, 95, 230, 0.5);
    box-shadow: 0px 0px 20px -5px rgba(5, 95, 230, 0.5);
    border-radius: 100px;
    color: $main-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    text-align: center;
    margin: 0.5em;
    position: relative;

    p {
        margin: 0;
    }

    img {
        height: 60px;
        line-height: 16px;
    }
}

@media (max-width:420px) {

    .featureCard {
        width: 120px;
        height: 120px;
        padding: 10px;

        img {
            height: 50px;
        }

        p {
            font-size: 14px;
        }
    }
}