@import '../../../shared.scss';

.featuresContainer {
    padding-top: 6em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    position: relative;
    z-index: 10;

    .trialBtn{
        @include solidButton(white, $main-blue--bright, 17px, 40px, 420px);
        margin: 40px auto;
      }

    .grid {
        display: flex;
        justify-content: space-evenly;
        padding: 0 3em;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1200px;
    }

    h2 {
        color:$main-blue;
        margin-bottom: 2em;
    }

    .carouselContainer {
        width: 100%;


        .centerCarousel {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .slider {
            width: 100%;
            height: 400px;
            padding: 2em 0em;

            .slide {
                display: flex;
                flex-wrap: wrap;
                width: 400px;
                height: 390px;
                margin: 0 auto;
            }
        }
        

        .dots{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            padding-bottom: 1em;;
            
            
        }
    
        .dot{
            width: 8px;
            height: 8px;
            border: 2px solid $main-blue;
            background-color: transparent;
            border-radius: 100px;
            margin: 0px 3px;
            transition: all 0.5s ease;
    
            &.active{
                background-color: $main-blue;
                width: 13px;
                height: 13px;
            }
        }
        .buttons{
            display: flex;
            justify-content: center;
        }
    }


}

@media (max-width:1150px) {

    .featuresContainer {
        padding-top: 9em;

        h2 {
            margin-bottom: 1em;
        }
    }
}

@media (max-width:420px) {
    .featuresContainer {

        .carouselContainer {
            width: 100%;


            .centerCarousel {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            .slider {

                height: 300px;
                padding: 2em 0em;

                .slide {
                    display: flex;
                    flex-wrap: wrap;
                    width: 320px;
                    height: 300px;
                    margin: 0 auto;
                }
            }
        }
    }
}