@import "./../../WritingBee.module.scss";

.authorResponsive {
  margin: 3em 20px 3em;
  display: none;
  transform: translateZ(1px);

  .bigAuthorCard {
    border-radius: 20px;
    padding: 10em 0 3em;
    margin-bottom: 10em;
    position: relative;
    .cardBody {
      border-radius: 20px;
      transform: translateZ(1px);
      color: white;
      padding: 2em;
      text-align: left;

      h3 {
        text-align: center;
        font-size: 35px;
        line-height: 40px;
      }
    }
    .bigKinney,
    .bigCraft {
      position: absolute;
      width: 280px;
      top: 0;
      left: calc((100% - 280px) / 2);
      margin: 0 auto;
      transform: translateY(-78px);
    }

    .orangeBtn {
      @include btn(white, $main-orange--bright, 0em);
      margin: 0.75em auto;
      display: block;
    }

    
    .kinneyDeco {
      position: absolute;
      left: calc((100% - 150px) / 2);
      width: 150px;
      bottom: -70px;
    }
    .craftDeco{
      position: absolute;
      left: calc((100% - 120px) / 2);
      width: 120px;
      bottom: -90px;
    }

    &.kinney {
      border: 5px solid $main-blue;
      background-color: #a0d1f1;
      .cardBody {
        background: linear-gradient(270deg, #2b4e7b 0%, rgba(43, 78, 123, 0.77) 100%),
          url("./../../../../Assets/WritingBee/kinneybg.png");
      }
    }
    &.craft {
      border: 5px solid #575757;

      background-color: #fff30e;
      .cardBody {
        background: linear-gradient(270deg, #575757 0%, rgba(87, 87, 87, 0.77) 100%),
          url("./../../../../Assets/WritingBee/craftbg.png");
      }
    }
  }
}

@media (max-width: 950px) {
  .authorResponsive {
    display: block;
  }
}

@media (max-width: 520px) {
  .authorResponsive {
    margin: 2em 20px 3em;
  }
}
