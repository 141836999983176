@import './../../WritingBee.module.scss';

.opinion{
  margin:4em auto 0em;
  max-width: 760px;
  display:flex;
  @include shadow(25px,rgba(0,0,0,0.3));
  border-radius:20px;
  position: relative;
  // z-index: 2;
  background-color: white;
  transform-style: preserve-3d;

  &:before{
    content: '';
    background-color: $main-yellow;
    position: absolute;
    width: 115%;
    height: 340px;
    // z-index: 0;
    border-radius: 20px;
    transform: translateZ(-1px) rotate(-2deg);
    left: -50px;
    top:55px
  }
  .textDiv{
    text-align: left;
    padding: 3em;
    p{
      margin: 0;
    }
    .mainText{
      margin-top: 2em;
      font-size: 16px;
      color: rgba(75, 74, 74, 0.8); ;
    }
  }
}

@media(max-width:850px){
  .opinion{
    margin: 4em 2em 0;
    flex-direction: column;
    img{
      width: 240px;
      margin: 2em auto 0;
    }
  }
}

@media(max-width:420px){
  .opinion{
    img{
      width: 200px;
      margin: 2em auto 0;
    }
    .textDiv{
      padding: 2em;
    }
  }
}