.containerNoFlex {
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
    padding: 0 2rem;
    display: block;

}

.containerNoFlex h1 {
    font-size: 40px;
}

.containerNoFlex a {
    color: #52B5FF;
    text-decoration: none;
}

table {
    border-spacing: 0px;
}

.privacyTable {
    margin: 2rem 0;
    border: 1px solid #cccccc;

}

.privacyTable thead {
    display: table-row-group;
    color: #ffffff;
    background: #52B5FF;
}

.privacyTable tr th td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;

}

.privacyTable th {
    padding: 1rem;
}

.privacyTable tr {
    display: table-row;
    border: none;
    border-bottom: 1px solid;
    margin-bottom: 10px;
}

.privacyTable td {
    display: table-cell;
    width: 50%;
    border-bottom: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    padding: 1em;
}

.privacyTable td:last-child {
    border-right: none;
}





.privacyDefinitionList dt {
    flex: 1;
    width: 200px;
    padding: 0.5rem 1rem 0.5rem 0;
    font-weight: 700;
    display: inline-flex;
    margin-top: 1rem;

}

.privacyDefinitionList {
    position: relative;
}

.privacyDefinitionList dd {
    flex-direction: column;
    width: calc(100% - 255px);
    padding: 0.5rem 0 0.5rem 1rem;
    border-top: none;
    margin: 0;
    display: inline-flex;
    margin-top: 1rem;
}

.privacyDefinitionList dt,
.privacyDefinitionList dd {
    display: inline-flex;
    margin-top: 1rem;
}


.privacyDefinitionList dd:after {
    content: '';
    width: 100%;
    height: 1px;
    background: #cccccc;
    position: absolute;
    left: 0;
    margin-top: -1rem;
}

.terms-privacy .heading,
.terms-privacy .headingHero {
    /* color: #52B5FF; */
    margin: 2rem 0 2rem;
    font-size: 32px;
}