@import "./../../../../shared.scss";

.card {
    display: flex;
    justify-content: space-around;
    margin: 1em 0;
    position: relative;
    //
    .bookWrapper {
        width: 180px;
        height: 155px;
        padding: 0.5em;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .books {
            width: 250px;
            position: absolute;
        }
    }
    .imgWrapper {
        -webkit-box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
        -moz-box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
        box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
        background-color: white;
        width: 180px;
        height: 155px;
        padding: 0.5em;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .cardNumber {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $main-blue--light;
        // border-radius: 150px;
        color: white;
        width: 60px;
        height: 60px;
        font-weight: bold;
        font-size: 40px;
        line-height: 35px;
        box-shadow: 10px 10px 25px rgba(3, 8, 53, 0.1);
        border-radius: 30px;
    }

    .decoration {
        padding: 0em 5em;
    }

    .curvedLine {
        margin-top: 5px;
        width: 15px;
        position: absolute;
        margin-left: 2.5em;

        &.reversed {
            transform: rotate(-180deg);
            margin-left: 0.5em;
        }
    }

    .text {
        text-align: left;

        color: $main-blue;

        h3 {
            font-weight: bold;
            font-size: 35px;
            line-height: 35px;
        }

        p {
            max-width: 630px;
            font-size: 18px;
        }
    }
}

.pseudoParent {
    position: absolute;
    height: 50%;
    width: 1400px;
    background: linear-gradient(158.05deg, #ffa826 14.37%, #ff8258 93.54%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    top: 35%;
    border-radius: 40px;
    z-index: 1;
}

@media (max-width: 1200px) {
    .card {
        padding: 0 2em;
        flex-direction: column;

        .imgWrapper,
        .bookWrapper {
            height: auto;
            position: absolute;
            width: 85px;
            height: 72px;
            left: 70%;
            top: 5%;
            .books {
                width: 150%;
            }
        }

        .decoration {
            padding: 0;
        }

        .curvedLine {
            display: none;
        }

        .text {
            h3 {
                max-width: 250px;
                font-size: 30px;
                line-height: 35px;
            }
        }
    }
}

@media (max-width: 430px) {
    .card {
        padding: 0 2em;
        flex-direction: column;

        .imgWrapper {
            left: 60%;
        }

        .text {
            h3 {
                max-width: 150px;
                font-size: 26px;
                line-height: 30px;
            }
        }
    }
}
