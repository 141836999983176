@import '../../../../shared.scss';

.container{
  display: flex;
  justify-content: space-between;
  max-width: initial !important;
  width: 100%;
  // min-width: 1100px;
  position: relative;


  

  .back{
    position: absolute;
    top:-20px;
    left:5px;
    color:$main-blue;
    font-weight: 700;
    cursor: pointer;

    &:hover{
      text-decoration: underline;
    }
  }

  >div{
    margin-top: 15px;

    -webkit-box-shadow: 1px 1px 10px 1px rgba(0, 89, 255, 0.2);
    -moz-box-shadow:    1px 1px 10px 1px rgba(0, 89, 255, 0.2);;
    box-shadow:         1px 1px 10px 1px rgba(0, 89, 255, 0.2);;
    border-radius: 20px;
    background-color: white;
  }

  .orderSummary{
    
    &.stepThree{
      width: 100%;
      // max-height: 225px;
      height: fit-content;
    }

  }

  .orderSummaryBlock{
    width:350px;
    height: fit-content;
    max-height: 460px;
    box-sizing:border-box;
    padding:15px 30px;

    h5{
      color: $main-blue;
      text-align: left;
      font-size: 18px;
      line-height: 23px;
      width: 230px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      margin-bottom: 10px;

      img{
        height: 44px;
        width: 44px;
        object-fit: contain;
      }
    }

    .bookDetails{
      width: 100%;
      border-bottom: 1px solid #cacaca;
      box-sizing:border-box;
      padding:0 0 15px 0;
    }

    .orderSummaryLower{
      width: 80%;
      h5{
        margin:15px 0 10px 0;
        // font-size: 16px;
      }

    }

    .row{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 35px;

      &.short{
        height: 30px;
      }

      p.item{
        font-size: 16px;
        color: #2b4e7baa;
        font-weight: 500;
      }
      p.data{
        font-size: 18px;
        color: #2b4e7b;
        font-weight: 700;
      }

      &.creditDiscount{
        p.item{
          font-size: 14px;
          color: red;
        }
        p.data{
          font-size: 16px;
          color: red;
          position: relative;

          .bar{
            position: absolute;
            height: 2px;
            left:-10%;
            top:50%;
            transform: rotate(-10deg);
            background-color: red;
            width: 120%;
          }
        }
      }
    }

    p.subtext{
      text-align: left;
      color:$main-blue;
      font-size: 13px;
    }
  }

  .mainArea{
    width: calc(100% - 380px);
    min-height: calc(100% - 30px);
    box-sizing:border-box;
    padding:30px 40px;
    position: relative;



    .loadingOverlay{
      min-height: 100%;
      min-width: 100%;
      background-color: rgba(255, 255, 255, 0.85);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top:0;
      left: 0;
      z-index: 100;
      // pointer-events: none;

      img{
        height: 100px;
        width: 100px;
        object-fit: contain;
        position: relative;
        bottom:-10px;
        
        &.sml{
          bottom:0px;
          height: 60px;
          width: 60px;
        }
      }
      p{
        position: relative;
        bottom:20px;
        font-size: 17px;
        color:$main-blue;
      }
  }

    .steps{
      width: 100%;
      display: flex;
      justify-content: center;
      height: 70px;
      margin-bottom: 20px;


      .inner{
        display: flex;
        justify-content: space-between;
        position: relative;
        height: 70px;
        width: 350px;

        .line{
          position: absolute;
          z-index: 0;
          top:21px;
          left:25px;
          height: 1px;
          width: 300px;
          background-color: #cacaca;
        }

        .step{
          width:90px;
          height: 65px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
  
          h6{
            color:#858585;
            font-size: 14px;
            margin:0;
          }
  
          .bubble{
            min-height: 40px;
            max-height: 40px;
            min-width: 40px;
            max-width: 40px;
            border-radius: 50%;
            position: relative;
            z-index: 10;
  
            &.blue{
              background-color: $main-blue--bright;
            }
  
            &.green{
              background-color: $main-green;
            }
            &.grey{
              background-color: #d2d2d2;
              p{
                color: #858585;
              }
            }
  
            p{
              margin:0;
              line-height: 40px;
              font-size: 25px;
              color:white;
              font-weight: 700;
            }
          }
  
        }
      }


    }
  }

  .findChild{
    position: relative;
    text-align: left;
    height: fit-content;
    margin-bottom: 60px;
    min-height: 100px;
    
    h3{
      font-size:18px;
    }

    h4{
      font-size: 15px;
      font-weight: 900;
    }
    p{
      font-size: 15px;
    }

   

    .surnameInputDiv{
      position: relative;

      display:flex;
      align-items: center;

      input[type='text']{
        height: 40px;
        border:1px solid #cacaca;
        border-radius: 5px;
        box-sizing:border-box;
        padding:0 10px;
        width:400px;
        margin:15px 10px 15px 0;
      }

      .surnameSearchBtn{
        @include solidButton(white, $main-blue--bright, 16px, 35px, 120px)
      }

    }

    .resultsDropdown{
      width:400px;
      z-index: 200;
      max-height: 100px;
      overflow-y: scroll;
      background-color: white;
      -webkit-box-shadow: 1px 1px 10px 1px rgba(3, 20, 51, 0.2);
      -moz-box-shadow:    1px 1px 10px 1px rgba(3, 20, 51, 0.2);;
      box-shadow:         1px 1px 10px 1px rgba(3, 20, 51, 0.2);;
      border-radius: 5px;
      background-color: white;
      position: absolute;
      top:56px;

      .dropdownRow{
        text-align: left;
        box-sizing:border-box;
        padding:0 15px;
        cursor: pointer;
        height: 35px;
        line-height: 35px;
        font-size: 16px;
        color: #333333;

        &:hover{
          background-color: #f2f2f2;
        }

        &.disabled{
          pointer-events: none;
        }
      }
    }

    .resultsRow{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 220px;
      height: 30px;
      margin:5px 0;
      position: relative;

      input[type='number']{
        width: 45px;
        height: 28px;
        border-radius: 3px;
        border:1px solid #cacaca;
        box-sizing: border-box;
        padding-left: 5px;

        &::-webkit-inner-spin-button{
          opacity: 1;
          height: 20px;
          cursor: pointer;
        }
      }

      .deleteCircle{
        position: absolute;
        right: -23px;
        height:17px;
        width:17px;
        background:url('../../../../Assets/Icons/icon_delete_circle--inactive.svg') no-repeat center;
        background-size: contain;
        cursor: pointer;
        transition: all .1s ease-in;
        
        &:hover{
          background:url('../../../../Assets/Icons/icon_delete_circle--active.svg') no-repeat center;
        }
      }
    }



  }

  .checkoutStepTwo{

    .topBlocks{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }


    .shippingDetails, .addressDetails {

      .yourDetails {
        flex-direction: column;
        text-align: left;
        align-items: space-evenly;
        width: 100%;
        @media (max-width:1250px){
          width: 95%;
          margin: 0 auto;
        }
      }

      h3 {
        text-align: left;
        font-size: 17px !important;
        margin: 20px 0 5px 0;
      }

      h5 {
        text-align: left;
        margin:15px 0 5px 0;
        font-size: 16px;
      }

      div.name {
        position: relative;
        top:3px;
        display: flex;
        margin:0;
      }

      input[type='text'],
      input[type='email'],
      input[type='tel'] {
        height: 35px;
        width: 600px;
        border-radius: 5px;
        border: 1px solid #cacaca;
        box-sizing: border-box;
        padding: 0 10px;
        display: block;
        margin:6px 7px 6px 0;

        @media (max-width:1250px){
          width: 100%;
        
        }
      }
      @media (max-width:1250px){
        width: 95%;
        margin: 0 auto;
      }
    }

    .addressDetails{
      input[type='text']{
        width: 600px;
        @media (max-width:1250px){
          width: 100%;
        }
      }

    }


    
    
  }

  .checkoutStepThree{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;


    &.purchaseComplete{
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      @media (max-width:1250px){
        width: 100%;
      }
    }


    .confOverlay{
      min-height: 100%;
      min-width: 100%;
      background-color: rgba(179, 179, 179, 0.3);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top:0;
      left: 0;
      z-index: 100;
      // pointer-events: none;

      img{
        height: 100px;
        width: 100px;
        object-fit: contain;
      }
      p{
        position: relative;
        bottom:10px;
        font-size: 17px;
        color:$main-blue;
      }
  }

  .cardDetailsDiv{
    p.paymentError{
      color:red;
      position: relative;
      bottom:10px;
      font-size: 14px;
      padding-left: 5px;
    }
  }

  }
  

  .nextBtn{
    @include solidButton(white, $main-blue--bright, 16px, 40px, 170px);
    margin-top:40px;
    overflow: visible;
    position: relative;

    .error{
      width: 250px;;
      color:red;
      font-size: 15px;
      position: absolute;
      left:180px;
      top:0px;
      text-align: left;
    }
  }

  .purchaseBtn{
    @include solidButton(white, $main-blue--bright, 16px, 40px, 170px);
    margin-top:40px;
    overflow: visible;
    position: absolute;
    bottom:30px;

    .error{
      text-align: left;
      width: 250px;
      color:red;
      font-size: 15px;
      position: absolute;
      left:180px;
      top:0px;
    }

    &.disabled{
      opacity: .5;
      pointer-events: none;
    }
  }
  
}

.orderDetailsDiv{
  height: 260px;
  width: 49%;
  min-width: 320px;
  margin-bottom: 20px;

  &.purchaseComplete{
    height: fit-content;
    width: 600px;
  }

  @media (max-width:1250px){
    width: 100% !important;
    height: fit-content;
  }

}

.orderSummary,
.shippingDetailsBlock, 
.creditsDetailsBlock, 
.shippingOptionsBlock{
  border:1px solid #cacaca ;
  text-align: left;
  width: 50%;
  min-width: 320px;
  max-height: 225px;
  overflow-y: auto;
  border-radius: 15px;
  background-color:#f9f9f9;
  position: relative;
  box-sizing:border-box;
  padding:20px 30px;
  overflow: auto;


  @media (max-width:1250px){
    width: 100% !important;
  }


  &.stepThree{
    height: 260px;
    width: 49%;
    min-width: 370px;
    max-height: initial;

  }

  .colorTab{
    height: 60%;
    width: 13px;
    border-radius: 60px;
    background-color: #8e00f0;
    position: absolute;
    top:20%;
    left:-6px;
  }

  p{
    font-size: 16px;
    color:$main-blue;
    margin:5px 5px 5px 0;
  }

  p.editBtn{
    position: absolute;
    top:15px;
    right:20px;
    font-size: 14px;
    color:$main-blue--bright;
    cursor: pointer;
  }

  h3{
    color:$main-blue;
    font-size: 20px;
  }

  h4{
    color:$main-blue;
    font-size: 16px;
  }

  .typeRow, .titleRow, .row{
    display: flex;
    height: 35px;
    align-items: center;
  }

    .titleRow, .row{
      width: 100%;
      height: 30px;
      justify-content: space-between;
  }

  .addressDiv{
    p{
      line-height: 22px;
      margin: 0;
    }
  }

}

.shippingDetailsBlock{
  overflow: hidden;

  h3{
    line-height: 25px
  }
  p{
    margin:3px 0;
    line-height: 20px;

    &.shippedBy{
      margin-top:10px;
    }
  }
  
  h4{
    line-height: 20px;
    margin-top: 10px;
  }

  &.purchaseComplete{
    width: 600px;
  }
}

.creditsDetailsBlock{
  position: relative;
  max-height: initial;

  .creditBalanceBlock{
    position: absolute;
    top:15px;
    right:15px;
    height: 60px;
    width: 85px;
    border-radius: 10px;
    background: rgb(12,167,216);
    background: linear-gradient(124deg, rgba(12,167,216,1) 0%, rgba(46,102,174,1) 78%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing:border-box;
    padding:10px !important;  

    h6{
      font-size: 14px;
      color:white;
      margin:0;
      background:url('../../../../Assets/Icons/credit-small-coin.svg') no-repeat left center;
      padding-left: 15px;
      font-weight: 500;
      // border:1px solid chartreuse;
    }
    h2{
      font-size: 20px;
      line-height: 24px;
      color: white;
      margin:0;
      // border:1px solid chartreuse;
    }
  }


  .remainingBalance{
    height: 25px;
    overflow: hidden;

    p{
      margin:0
    }
  }

  .colorTab{
    background-color: $main-blue--light;
  }

  .quantityDiv{
    display: flex;
    align-items: center;

    input[type='number']{
      width: 45px;
      height: 28px;
      border-radius: 3px;
      border:1px solid #cacaca;
      box-sizing: border-box;
      padding-left: 5px;
      margin-left: 15px;

      &.exceed{
        border-color: red;
      }

      &::-webkit-inner-spin-button{
        opacity: 1;
        height: 20px;
        cursor: pointer;
      }
    }
  }

  span.exceed{
    color: red;
  }

  .redeemBtn{
    @include solidButton(white, $main-blue--light, 16px, 35px, 120px);
    margin-top: 20px;
    position: relative;

    .error{
      text-align: left;
      width: 250px;
      color:red;
      font-size: 15px;
      position: absolute;
      left:130px;
      top:0px;
    }
  }
  @media (max-width:1250px){
    margin-bottom: 60px;
  }
}

.shippingOptionsBlock{

  margin-top: 20px;
  height: 200px;
  width: 420px;
  overflow: hidden;
  box-sizing:border-box;
  padding:15px 20px 15px 30px;

  h3{
    margin:0px 0 15px 0!important;
  }

  .colorTab{
    height: 70%;
    width: 13px;
    border-radius: 60px;
    background-color: #8e00f0;
    position: absolute;
    top:15%;
    left:-6px;
  }

  .optionsDiv{
    height: 125px;
    overflow: scroll;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: rgb(220, 220, 220);
      border:1px solid rgb(220, 220, 220);
    }

    
    &.loading{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // border:1px solid chartreuse;

      img{
        height: 50px;
        width: 50px;
        // position: relative;
        // top:5px;
        position: relative;
        top:18px;
      }
      
      p{
        
        position: relative;
        top:5px;
        font-size: 14px;
      }
    }

    .shippingOptionRow{
      width: 100%;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      line-height: 35px;
      // border:1px solid chartreuse;
      
      *{
        height: 35px;
        text-align: left;
        width:30%;
        line-height: 35px;
        // border:1px solid chartreuse;
      }

      .name{
        width: 75%;
        box-sizing:border-box;
        padding:0 0 0 5px;
        font-size: 14px;
        font-weight: 500;
        box-sizing:border-box;
        padding:0 0 0 10px;
        // position: relative;
        // right: 10px;
      }
      // .eta{
      //   width: 45%;
      // }
      .price{
        width: 25%;
        font-size: 16px;
        font-weight: 700;
        color: $main-blue--bright
      }
    }

    label{
      cursor: pointer;
      // width: 90%;
      box-sizing:border-box;
      padding:0 20px;
      margin:0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border-bottom:1px solid #8585854f;
      
      &:first-of-type{
        border-top:1px solid #8585854f;

      }

      &:hover{
        background-color: #f2f2f2;
      }
    }

    label>input[type='radio']{
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: 0;
      background: rgb(220, 220, 220);
      min-height: 14px;
      min-width: 14px;
      border: 0px solid white;
      border-radius: 2px;
      margin-right: 10px;
    }

      input[type="radio"]:checked {
        background: $main-blue--bright;
        border: 2px solid rgb(220, 220, 220);;
      }

  }

}

.cardDetailsDiv{
  width: 49%;
  min-height: 260px;

  @media (max-width:1250px){
    width: 95%;
    margin:20px auto;
  }
  
  
  *{
    text-align: left !important;
  }
  
  .cardDetailsBlock{
  
      label, input{
        display: block;
      }
      label{
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
        margin-top: 15px;
      }
  
      input{
        font-family: 'Sofia Sans', sans-serif;
        box-sizing:border-box;
        padding-left:20px;
        height: 35px;
        line-height: 35px;
        width: 100%;
        border-radius: 6px;
        border:1px solid #cacaca;
  
  
      }
      input[name="number"]{
        background:url('../../../../Assets/Icons/icon-visa--card.svg') no-repeat 10px;
        // background:url('../../../../assets/icon-visa--card.svg') no-repeat 10px;
        padding-left: 50px;
      }
  
      input[type=number]::-webkit-inner-spin-button, 
      input[type=number]::-webkit-outer-spin-button { 
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0; 
      }
  
      .bottom{
        display: flex;
        justify-content: space-between;
        label{
          display: block;
        }
        div{
          width: 47%;
  
        }
      }
    }
}

