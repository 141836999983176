@import "./../HomePage.module.scss";
.writingBeeContainer {
    max-width: 1180px;
    height: 450px;
    margin: 10em 10px;
    background-color: $main-grey--bee;
    border-radius: 20px;
    background-image: url("./../../../Assets/WritingBee/wordsBg.png");
    background-size: cover;
    position: relative;
    overflow: hidden;
    .map {
        position: absolute;
        width: 1180px;
        height: 50%;
        bottom: 0;
        background-image: url("./../../../Assets/WritingBee/orangeWorld.png");
        background-size: cover;
        // z-index: -1;
    }

    .bigImg {
        width: 370px;
        position: absolute;
        opacity: 1;
        top: 50px;
        &.left {
            left: -24px;
        }
        &.right {
            right: -24px;
        }
    }

    .text {
        color: white;
        max-width: 500px;
        font-size: 40px;
        line-height: 40px;
        margin: 0.5em auto 0em;
        z-index: 3;
    }

    .beeLogo {
        padding-top: 3em;
    }
    .mobileBeeLogo {
        display: none;
        padding-top: 3em;
    }
    .scrollbtn {
        @include btn(white, $main-grey--bee, 2em);
        position: absolute;
        bottom: 30px;
        left: calc((100% - 150px) / 2);
    }
}

@media (max-width: 1180px) {
    .writingBeeContainer {
        .map {
            width: 100%;
        }
        .bigImg {
            display: none;
        }
        .text {
            font-size: 30px;
        }
    }
}
@media (max-width: 650px) {
    .writingBeeContainer {
        .mobileBeeLogo {
            display: block;
        }
        .map {
            height: 30%;
            background-position-x: center;
        }
        .beeLogo {
            display: none;
        }
    }
}
