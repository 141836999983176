@import "./../../shared.scss";

$bee-grey: #f4f4f4;
$bee-black: #4b4a4a;



section {
  color: $bee-black;

  h2 {
    color: $bee-black;
  }
}

figure{
  margin:0
}

.orangeBtn {
  @include btn(white, $main-orange--bright, 3em);
}

@media(max-width: 680px){
  .handleMobile{
    margin-top: -50px;
  }
}

.blueBtn {
  @include btn(white, $main-blue, 1em);
}
.aquamarineBtn {
  @include btn(white, $main-blue--light, 1em);
}
