@import "./../../WritingBee.module.scss";

.sponsors {
  flex-direction: column;
  margin-top: -8em;
  transform: translateZ(1px);

  .preSponsors {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1em;
    text-align: center;
    h3{
      color:white ;
      margin: 0 1em;
    }
  }

  .sponsorContainer {
    background-color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 20px;
    @include shadow(25px, rgba(0, 0, 0, 0.3));
    padding: .5em 5em;
    margin: 0 auto;

    img{
      width: 190px;
    }
    div{
      margin: 20px;
    }
  }
}

@media (max-width: 1300px) {
  .sponsors {
    margin-top: -40px;
  }
}

@media (max-width: 1200px) {
  .sponsors {
    margin: -3em 1em 0em;

    .sponsorContainer{
      width: 550px;
    }
  }
}

@media (max-width: 950px) {
  .sponsors {
    margin: 2em 1em;
    .preSponsors{
      h3{
        color: $main-black;
      }
    }

    .sponsorContainer{
      flex-wrap: wrap;

      div{
        margin: 1em;
      }
    }
  }
}

@media (max-width: 620px) {
  .sponsors {
   display: none;
  }
}
