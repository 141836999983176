@import './../../../../shared.scss';

$ease: ease;
$duration: 1500ms;

.piece {
  margin: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
  -moz-box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
  box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
  background-color: white;
  padding: 1.5em;
  margin-bottom: 1em;
  position: relative;
  transition: all 1.5s ease;
  text-align: left;

  &.active {

      justify-content: flex-start;
      padding-bottom: 280px;

      img {
          // margin-top: 1.5em;

          left: 30%;
          display: block;
          opacity: 1;
          animation: fadeInFromNone 2s ease-out;

      }
  }

  @keyframes fadeInFromNone {
      0% {
          display: none;
          opacity: 0;
      }

      100% {
          display: block;
          opacity: 1;
          animation: fadeInFromNone 0.5s ease-out;
      }
  }

  p {
      font-size: 16px;
  }

  .plusToMinus {
      width: 20px;
      height: 20px;
      cursor: pointer;
      transition: transform $ease $duration;
      position: absolute;
      top: 20px;

      left: calc(100% - 40px);

      &:before,
      &:after {
          content: '';
          position: absolute;
          background-color: $main-blue;
          border-radius: 2px;
      }

      &:before {
          left: 50%;
          margin-left: -1px;
          width: 2px;
          height: 20px;
      }

      &:after {
          top: 50%;
          margin-top: -2px;
          height: 2px;
          width: 20px;
          transition: transform $ease $duration;
      }

      &.minus {
          transform: rotate(-90deg);

          &:after {
              transform: rotate(-90deg);
          }
      }
  }

  img {
      position: absolute;
      display: none;
      opacity: 0;
      width: 350px;
  }
}


@media (max-width:550px) {

  .piece {
      &.active {
          img {
              left: 10%;
          }
      }
  }

}

@media (max-width:450px) {

  .piece {
      &.active {
          img {
              left: 5%;
              width: 90%;
          }
      }
  }


}

@media (max-width:380px) {


  .piece {
      &.active {
          img {
              margin-top: 2em;

          }
      }
  }

}
