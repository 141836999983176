@import './../../../../shared.scss';

.trustCard {
    width: 580px;
    height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    border-radius: 20px;
    background-color: white;

    .padding {
        padding: 3em;
    }


    .quote {
        border-radius: 20px;
        background-color: #065FE5;
        color: white;
        width: 60px;
        height: 60px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .twWrapper {
            width: 25px;
            height: 25px;

            img {
                width: 25px;
                height: 25px;
            }
        }

    }

    .bodyText {
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        margin: 1.5em 0 2.5em;
    }

    .personalInfo {
        display: flex;

        .imgWrapper {
            // background-color: #065FE5;
            border-radius: 100px;
            height: 120px;
            width: 120px;
            margin-right: 3em;


        }


        p {
            margin: 0em;
            color: rgba(43, 78, 123, 0.6);
            font-weight: bold;
        }
    }


}

@media (max-width:950px) {
    .trustCard {
        width: 250px;
        height: 450px;
        -webkit-box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
        -moz-box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
        box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
        padding: 1.75em;
        margin: 0 auto;

        .padding {
            padding: 0em;
        }

        .bodyText {
            height: 180px;
            font-size: 16px;
            line-height: 20px;
            margin: 1.5em 0 3.5em;
        }

        .personalInfo {
            .imgWrapper {
                height: 80px;
                width: 80px;
                margin-right: 2em;
                z-index: 10;
            }

            p {
                font-size: 16px;
            }
        }
    }
}

@media (max-width:350px) {
    .trustCard {
        padding: 0.75em;
    }
}