@import '../../shared.scss';


.titleContainer {
    padding-left: 2em;
    padding-top: 4em;
    padding-bottom: 22em;
    color: white;
    max-width: 1300px;
    text-align: left;

    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;

    h1 {
        font-weight: 600;
        font-size: 50px;
        line-height: 38px;
    }

    p {
        font-size: 18px;
        line-height: 26px;

        max-width: 670px;

    }
}

.fContainer {
    display: flex;
    flex-wrap: wrap;
    margin-right: auto;
    margin-left: auto;
    max-width: 1200px;
    padding-right: 10px;
    padding-left: 10px;
    justify-content: space-evenly;

    padding-top: 140px;
}

.whiteWave {
    background-repeat: no-repeat;
    // background-size: cover;
    background-image: url('../../Assets/Backgrounds/whiteWave.svg');
    margin-top: -350px;

}

.lastWave {


    h2 {
        padding-top: 4em;
        font-weight: 600;
        font-size: 50px;
        line-height: 38px;
        max-width: 1300px;
        margin-right: auto;
        margin-left: auto;
        color: $main-blue;
    }
}