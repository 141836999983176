@import "../../../shared.scss";

.cookieModal {
    background: rgba(7, 124, 160, 0.75);
    backdrop-filter: blur(28px);
    border-radius: 20px;
    position: fixed;
    bottom: 2%;
    right: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    max-width: 450px;
    // width: 300px;
    z-index: 300;

    .cookie {
        width: 90px;
    }

    p {
        color: white;
        font-size: 12px;
        line-height: 20px;
        text-align: left;
    }

    a {
        color: $main-orange--bright;
        text-decoration: underline;
        text-decoration-color: $main-orange--bright;
    }

    button {
        @include btn(white, $main-blue--bright, 3em);
    }

    .closingBtn {
        position: absolute;
        top: 5%;
        right: 5%;
    }
}

.hidden {
    display: none;
}

@media (max-width: 1250px) {
    .cookieModal {
        max-width: 400px;
        padding: 1em;
        .cookie {
            width: 70px;
        }

        p {
            margin: 0.5em 0em;
        }
        button{
            font-size: 10px;
        }
    }
}

@media (max-width: 650px) {
    .cookieModal {
        margin: 5px;
        max-width: 300px;
        padding: 3em 1em 1em 1em;
        bottom: 0%;
        right: 0%;

        p {
            font-size: 10px;
            margin: 0.5em 0em;
        }
    }
}
