@import '../../../shared.scss';

.container{
  flex-direction: column;
  align-items: center;

  @media (max-width:660px){
    width: 100vw;

    .cards{
      .card{
        .header{
          img{
            display: none;
          }
        }
      }
    }
  }


  h1{
    font-size: 50px;
    color: $main-blue;

    @media (max-width:660px){
      font-size: 35px;
      line-height: 45px;
      width: 85vw;
    }
  }

  h3{
    color:$main-blue;
    font-size: 30px;
    width: calc(100% - 450px);
    text-align: center;
    position: relative;
    bottom:80px;
    line-height: 35px;

    @media (max-width:1100px){
      width: 50%;
    }
    @media (max-width:900px){
      width: 80%;
      // bottom:130px;
    }
  }

.cardsContainer{
  width: 100%;
  -webkit-box-shadow: -1px -1px 20px 2px #065fe52e;
  -moz-box-shadow:    -1px -1px 20px 2px #065fe52e;
  box-shadow:         -1px -1px 20px 2px #065fe52e;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:110px 50px 0 50px;
  border-radius: 20px;
  position: relative;

  .cards{
    width: 95%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top:-70px;
    padding-bottom: 150px;

    .card{
      box-sizing:border-box;
      padding:10px 40px;
      height: 290px;
      width: 32%;
      max-width: 380px;
      border-radius: 20px;
      position: relative;
      overflow: hidden;

      @media (max-width:400px){
        width: 90% !important;
      }

      .header{
        height: 100px;
        width: 100px;
        display: flex;
        align-items: center;

        img{
          height: 70px;
          width: 70px;
          margin-right: 15px;
        }

        h5{
          font-size: 30px;
          font-weight: 700;
          margin:0;
        }
      }

      .ctaDiv{
        height: 35px;

        .learnMoreBtn{
          @include solidButton(white, $main-blue--bright, 14px, 35px, 240px);
          font-weight: 700;
          margin-top: 15px;
          visibility: hidden;
          position: relative;
          z-index: 10;
          transition: all .2s ease-in;

          span.res{
            display: none;
          }
        }

        @media (max-width:660px){
          margin-bottom: 20px;

          .learnMoreBtn{
            visibility:visible;
          }
        }
        @media (max-width:400px){
          margin-bottom: 20px;

          .learnMoreBtn{
            width: 150px;


          span.full{
            display: none;
          }
          span.res{
            display: initial;
          }
          }
        }
      }

      &:hover{
        .ctaDiv .learnMoreBtn{
          visibility:visible;
        }
      }


      p{
        color: #858585;
        text-align: left;
        font-size: 16px;
        line-height: 26px;
        margin:5px 0;

        &.sml{
          font-size: 14px;
          line-height: 20px;
        }
      }


      &.blue{
        background-color: #b5d8e3;

        img.boomer{
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 248px;
          object-fit: contain;
        }

      }
      &.green{
        background-color: #e0f2ba;

        img.boomer{
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 210px;
          object-fit: contain;
        }
      }
      &.orange{
        background-color: #ffe5be;

        .header{
          img{
            height: 90px;
            width: 90px;
            position: relative;
            top:8px;
            right:5px;
            margin-right: 5px;
          }
        }

        img.boomer{
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 344px;
          object-fit: contain;
        }
      }

    }

    @media (max-width:1100px){
      flex-direction: column;
      align-items: center;

      .card{
        max-width: initial;
        width: 75%;
        height: fit-content;
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing:border-box;
        padding:10px 40px 20px 40px;

        .header{
          width: 100%;
          justify-content: center;
        }


        p{
          text-align: center;
          font-size: 18px;
        }

        img.boomer{
          display: none;
          left: initial !important;
          right: 30px !important;
          position: relative;
          z-index: 1;
        }
      }
    }

    @media (max-width:1100px){
      padding-bottom: 50px;
    }
  }

  .booksContainer{
    // border:1px solid chartreuse;
    object-fit: contain;
    position: absolute;
    bottom: -0px;
    width:calc(100% - 100px);
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }


  img.book{
    height: 210px;
    width: auto;
    position: relative;
    bottom: -35px;

    &:first-of-type{
      left: 20px;
      // bottom: -23px;
      transform: rotate(-10deg);
    }
    &:last-of-type{
      right:20px;
      transform: rotate(10deg);
    }
    @media (max-width:900px){
    display: none;
    }
  }

  @media (max-width:1250px){
    width: 85%;
  }
}  
}