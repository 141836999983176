@import "./../../WritingBee.module.scss";

.authorFloatingCard {
  position: absolute;
  top: 19%;
  animation: dropTop 2s ease;

  &.kinney {
    left: 50px;
    .floatingDiv {
      left: -20px;
      background-color: #a0d1f1;
      border-color: $main-blue;
      animation: float 3s linear  infinite;
    }
  }
  &.craft {
    right: 50px;
    .floatingDiv {
      right: -20px;
      border-color: #575757;
      background-color: #fff30e;
      animation: float 2s linear  infinite;
    }
  }
  .floatingDiv {
    position: absolute;
    bottom: -20px;
    width: 390px;
    height: 390px;
    border-radius: 20px;
    border: 4px solid;
  }
}

@media (max-width: 1350px) {
  .authorFloatingCard {
    top: 26%;
  }
}
@media (max-width: 1100px) {
  .authorFloatingCard {
   display: none;
  }
}


@keyframes dropTop {
  0% {
    opacity: 0;
    transform: translateY(-200px);
  }
  50%{
    opacity: 0;
    transform: translateY(-200px);
  }
  
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes floatRight {
  0% {
    transform: translateX(-10px) translateY(-20px);
  }
  25% {
    transform: translateX(10px) translateY(0) rotate(2deg);
  }
  50% {
    transform: translateX(10px) translateY(20px) rotate(-2deg);
  }
  75% {
    transform: translateX(-00px) translateY(20px) rotate(2deg);
  }
  100% {
    transform: translateX(-10px) translateY(-20px);
  }
}

@keyframes floatLeft {
  0% {
    transform: translateX(0) translateY(0);
  }
  25% {
    transform: translateX(-50px) translateY(0) rotate(-2deg);
  }
  50% {
    transform: translateX(-50px) translateY(50px) rotate(2deg);
  }
  75% {
    transform: translateX(0px) translateY(50px) rotate(-2deg);
  }
  100% {
    transform: translateX(0) translateY(0);
  }
}

@keyframes float {
  from {
    transform: rotate(0deg) translate3d(5px, 0, 0) rotate(0deg);
  }

  to {
    transform: rotate(360deg) translate3d(5px, 0, 0) rotate(-360deg);
  }
}
