@import '../../../shared.scss';

.journalsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .groupWriting {
        background: linear-gradient(143.85deg, #FFA826 14.37%, #FF8258 93.54%);
        border-radius: 20px;
        width: 708px;
        height: 352px;
        padding: 1.5em 2.25em;
        margin-bottom: 2em;
        color: white;
        position: relative;
        margin-top: 10px;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        -moz-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);


        h2 {
            font-size: 35px;
            line-height: 49px;
            color: white;
            margin-bottom: 0.5em;
        }

        p {
            font-size: 18px;
            line-height: 26px;
            color: rgba(255, 255, 255, 0.8);
            max-width: 456px;
        }

        a {
            @include btn(white, $main-blue--bright, 2em)
        }

        .imgWrapper {
            position: absolute;
            width: 280px;
            top: calc(100% - 165px);
            left: calc(100% - 320px)
        }

    }

    .printJournals {
        width: 308px;
        height: 352px;
        border-radius: 20px;
        margin-top: 10px;
        padding: 1.5em 2.25em;
        margin-bottom: 2em;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: white;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        -moz-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);

        p {
            font-weight: bold;
            font-size: 26px;
            line-height: 26px;
            color: $main-black;
            width: 80%;
            margin: 0;
            margin-bottom: 10px;
        }

        .imgWrapper {
            img {
                width: 227px;
                // height: 183px;
                // margin-top: -10px;
            }
        }
    }

    .teacherCase {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        background-color: white;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        -moz-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        padding: 4em 2.25em 3em;
        border-radius: 20px;
        margin-bottom: 2em;

        .imgWrapper {
            width: 400px;
        }

        .text {
            max-width: 460px;

            h3 {
                font-size: 45px;
                line-height: 63px;

                color: $main-black
            }

            p {
                color: $main-grey;
                margin: 2em 0em;
            }

            a {
                @include btn(white, $main-blue--bright, 2em)
            }
        }
    }

    .flexibleTool {
        color: $main-blue;
        border-radius: 20px;
        padding: 2.75em 2.25em;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        -moz-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        margin-bottom: 2em;


        h3 {
            font-size: 30px;
            line-height: 40px;
        }

        a {
            @include btn(white, $main-blue--bright, 2em)
        }

        .listCarousel {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            .list {
                font-size: 16px;
                line-height: 18px;
                margin-top: 2em;
                max-width: 320px;

                li {
                    margin: 1.5em 0em;
                }
            }

            .carouselProvider {
                width: 730px;
            }

            .buttons {
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    display: inline-block;
                    font-size: 25px;
                    line-height: 26px;
                    padding: 0 2em;
                    // font-weight: 400;
                    color: rgba(7, 36, 72, 0.5);
                    transition: all 0.5s ease;
                }
            }
        }

    }

}

@media (max-width:1195px) {
    .journalsContainer {


        .groupWriting {
            width: 100%;
            margin-right: 0;
        }

        .printJournals {
            width: 100%;
        }

        .flexibleTool {
            text-align: center;

            ul {
                text-align: left;
            }
        }
    }

}

@media (max-width:1000px) {
    .journalsContainer {

        .flexibleTool {
            .listCarousel {

                .carouselProvider {
                    width: 600px;
                }
            }
        }
    }
}

@media (max-width:740px) {
    .journalsContainer {

        .flexibleTool {
            .listCarousel {

                .carouselProvider {
                    width: 500px;
                }
            }
        }
    }
}

@media (max-width:640px) {
    .journalsContainer {
        .groupWriting {
            text-align: center;
            height: 100%;

            h2 {
                font-size: 26px;
                line-height: 30px;
            }

            .imgWrapper {
                position: static;
                margin: 0 auto;
                margin-top: 1em;
                width: 300px;
            }
        }

        .teacherCase {
            .imgWrapper {
                width: 300px;
            }

            .text {
                text-align: center;

                h3 {
                    font-size: 30px;
                    line-height: 35px;
                }

                p {
                    font-size: 16px;
                    line-height: 23px;
                }
            }
        }

        .flexibleTool {
            .listCarousel {

                .carouselProvider {
                    width: 400px;

                    .centerCarousel {
                        .buttons {
                            justify-content: space-evenly;

                            p {
                                font-size: 20px;
                                line-height: 26px;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:540px) {
    .journalsContainer {

        .flexibleTool {
            padding: 1em 0.25em;

            h3 {
                font-size: 26px;
                line-height: 30px;
                padding: 0 1em
            }

            li {
                font-size: 16px;
                line-height: 18px;
            }

            .listCarousel {

                .carouselProvider {
                    width: 350px;
                }
            }
        }
    }
}


@media (max-width: 430px) {
    .journalsContainer {
        .groupWriting {
            padding: 1.5em 1.25em;

            .imgWrapper {

                width: 80%;
            }

        }

        .printJournals {
            padding: 1.5em 1.25em;
        }

        .teacherCase {
            padding: 1.5em 1.25em;

            .imgWrapper {
                width: 250px;
            }
        }

        .flexibleTool {
            .listCarousel {

                .carouselProvider {
                    width: 310px;
                }
            }
        }
    }
}

@media (max-width:350px) {
    .journalsContainer {

        .flexibleTool {
            .listCarousel {

                .carouselProvider {
                    width: 280px;
                }
            }
        }
    }
}