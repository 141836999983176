@import "../../../shared.scss";

$ease: ease;
$duration: 1500ms;
$bg1: #eb4d30;
$bg2: #64c4ca;
$bg3: #1e1e22;
$bg4: #0b435a;
$white: #fff;

.piecesContainer {
    display: flex;
    color: $main-blue;
    text-align: left;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 12em;

    .piece {
        max-width: 332px;
        height: 92px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 10px;
        -webkit-box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
        -moz-box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
        box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
        background-color: white;
        padding: 1.5em;
        margin-bottom: 0.825em;
        position: relative;
        transition: all 0.5s ease;

        p {
            max-width: 330px;
            margin: 1em 0 0 0;
            font-size: 14px;
            line-height: 16px;
        }
    }

    .active {
        max-width: 352px;
        -webkit-box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.5);
        -moz-box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.5);
        box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.5);
    }

    .active:before {
        content: "";
        height: 75%;
        width: 6px;
        background-color: $main-orange--bright;
        position: absolute;
        top: 12.5%;
        left: calc(100% - 6px);
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    .adjustAnimation {
        width: 450px;
        margin: 2em;
    }

    .text {
        margin-top: -10em;
        max-width: 580px;
        position: relative;

        h2 {
            max-width: 450px;
        }

        div {
            display: flex;
            align-items: center;

            p {
                max-width: 420px;
            }

            a {
                margin-left: 2em;
                @include btn(white, $main-blue--bright, 1.5em);
            }
        }

        .imgWrapper {
            // margin-top: 6em;
            width: 680px;

            margin-left: -6em;
            margin-top: -0.5em;

            img {
                margin-top: 5em;
                z-index: 10;
                -webkit-transition: opacity 1s ease-in-out;
                -moz-transition: opacity 1s ease-in-out;
                -o-transition: opacity 1s ease-in-out;
                transition: opacity 1s ease-in-out;
            }
        }

        .test {
            position: absolute;
            height: 380px;

            left: 20%;
            top: 42%;
            background-color: $main-orange--bright;
            z-index: 3;
            border-radius: 20px;
            width: 1900px;
        }
    }
}

.piecesMobileContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    // margin: 1em;
    color: $main-blue;
    background-color: $main-lightbluegrey;

    .text {
        margin-bottom: 3em;
        margin: 0 1em 2em;

        a {
            @include btn(white, $main-blue--bright, 1.5em);
        }
    }
}
