@import '../../shared.scss';

.container{
  width: 100%;
  max-width: 100%;
  min-height: 500px;
  padding: 40px 0 100px 0;
  margin: 0;
  background-color: $main-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;

  h1{
    margin: 10px 0 50px;
    max-width: 70vw;
    line-height: 70px;
    text-align: center;
    font-size: 52px;
    color: #eee;
  }

  .cards{
    width: 90%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    gap: 40px;

    @media (max-width:1150px){
      flex-direction: column;
      align-items: center;
    }
  }

}