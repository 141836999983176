@import "./../../Parents.module.scss";

.haunt {
  margin: 2em 20px;
  margin-bottom: -10em;
  z-index: 2;
  .container {
    @include container();
    @include borderShadowBox(2em);
    justify-content: space-evenly;
    transform: translateZ(1px);
    flex-wrap: wrap;

    img {
      max-width: 420px;
      margin: 1em;
    }
    .textContainer {
      background-color: white;
      max-width: 550px;
      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        /* or 133% */

        color: #2b4e7b;
      }
      p {
        width: 90%;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        /* or 144% */

        color: #2b4e7b;
      }
      a {
        @include btn(white, #ff8258, 2em);
      }
    }
  }
}

@media (max-width: 1095px) {
  .haunt {
    .container {
      justify-content: center;
      .textContainer {
        text-align: center;
        
        p {
          width: 100%;
        }
      }
    }
  }
}
