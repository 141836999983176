@import "./../../WritingBee.module.scss";

.faq {
  max-width: 760px;
  flex-direction: column;
  padding: 5em 0em;

  .questionCardContainer {
    display: flex;
    flex-direction: column;
    margin-top: 4em;
    z-index: 3;

    .questionCard {
      cursor: pointer;
      width: 100%;
      height: 70px;
      @include shadow(35px, rgba(0, 0, 0, 0.2));
      border-radius: 20px;
      background-color: white;
      display: flex;
      margin: 0.5em 0em;
      transition: 1s ease-in-out;
      position: relative;

      a{
        color: $main-blue--bright;
      }

      &:nth-child(odd) {
        &.active {
          height: 180px;
        }
      }

      &.active {
        height: 230px;
        svg {
          transform: rotate(180deg);
          fill: #4b4a4a;
        }
        p {
          opacity: 1;
        }
      }

      h5 {
        font-size: 20px;
        line-height: 30px;
        margin: 1em 2em 0;
      }

      svg {
        margin-top: 1.75em;
        margin-left: auto;
        margin-right: 2em;
        transition: 1s ease-in-out;
      }

      p {
        position: absolute;
        width: 80%;
        top: 40px;
        text-align: left;
        left: 40px;
        opacity: 0;
        transition: 0.8s ease-in-out;
      }
    }
  }
}

.faqSection {
  position: relative;
  z-index: 0;

  .sectionDecoration {
    position: absolute;
    width: 800px;
    height: 170px;
    background-color: $bee-black;
    border-radius: 10px;
    transform: rotate(-12deg);

    z-index: 0;
    &:after {
      content: "";
      width: 800px;
      height: 170px;
      position: absolute;
      bottom: 50px;
      left: 50px;
      background-color: $main-yellow;
      border-radius: 10px;
      transform: translateZ(-1px);
    }

    &.left {
      top: 300px;
      left: -200px;
    }

    &.right {
      // transform: rotate(10deg);
      top: 500px;
      right: -200px;
      &:after {
        left: -50px;
      }
    }
  }
}

@media (max-width: 790px) {
  .faq {
    .questionCardContainer {
      margin: 4em 1em 0;
      .questionCard {
        p{
          top: 60px;
          font-size: 16px;
        }
        h5 {
          display: flex;
          align-items: center;
          margin: 0.5em 2em;
          height: 50px;
        }
        &:nth-child(odd) {
          &.active {
            height: 250px;
          }
        }
  
        &.active {
          height: 300px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .faq {
    .questionCardContainer {
      .questionCard {
        p{
          font-size: 14px;
        }
        h5 {
          font-size: 16px;
          line-height: 18px;
        }
        &:nth-child(odd) {
          &.active {
            height: 320px;
          }
        }
  
        &.active {
          height: 400px;
        }
      }
    }
  }
}
