@import "../../../shared.scss";

.awardsContainer {
    margin-top: 20em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 4em;

    .text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
        margin-bottom: 2em;

        h2 {
            min-width: 350px;
            font-weight: bold;
            font-size: 30px;
            line-height: 35px;
        }

        img {
            width: 140px;
        }
    }

    .logoContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        background-color: white;
        border-radius: 20px;
        width: 90%;
        padding: 2em;
        -webkit-box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
        -moz-box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
        box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
        z-index: 3;
    }
}

.awardsContainerParents {
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 4em;
    margin: 0 auto;
    .text {
        margin-bottom: 2em;
        h2 {
            color: $main-blue;
            min-width: 350px;
            font-weight: bold;
            font-size: 30px;
            line-height: 35px;
        }

        img {
            width: 140px;
        }
    }

    .logoContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        background-color: white;
        border-radius: 20px;
        padding: 2em;
        -webkit-box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
        -moz-box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
        box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
        z-index: 3;
        a, div{
            margin: 0 2em;
        }
        
    }
}

@media (max-width: 950px) {
    .awardsContainer, .awardsContainerParents {
        .text {
            flex-wrap: wrap;

            h2 {
                min-width: auto;
                font-size: 25px;
                line-height: 30px;
            }
        }

        .logoContainer {
            width: auto;
            margin: 1em;
            padding: 0.5em;
        }
    }
}
