@import '../../../shared.scss';

.elementsContainer {

    display: flex;
    // flex-direction: column;
    align-items: center;
    position: relative;
}

.elementsCardContainer {
    display: flex;
    flex-direction: column;
    padding: 2em 4em 2em 10em;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
    -moz-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
    box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
    background-color: white;
    border-radius: 20px;
    margin-top: 2em;

}

@media(max-width:1200px) {
    .elementsContainer {
        width: 100%;
    }

    .elementsCardContainer {

        padding: 2em 0;
        width: 100%;
    }
}