@import "./../../WritingBee.module.scss";

.hero {
  background-color: $main-grey--bee;
  background-image: url("./../../../../Assets//WritingBee/wordsBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding-top: 3em;
  position: relative;

  h1 {
    margin: .6em 0em 0em 0em;
    font-size: 40px;
    line-height: 40px;
  }

  h4{
    margin: 1em 0em 0em 0em
  }

  h5{
    font-size: 18px;
    margin:15px 0 10px 0;
    text-decoration: underline;
    cursor: pointer;
  }

  .orangeBtn {
    @include btn(white, $main-orange--bright, 3em);
    margin: 1em 0;
  }

  .world {
    height: 500px;
    img {
      margin-bottom: -1em;
    }
  }

  .beeLogo {
    width: 600px;
  }

  .mobileBeeLogo {
    display: none;
    width: 300px;
  }
}

@media (max-width: 1375px) {
  .hero {
    .world {
      height: 400px;
      img {
        margin-bottom: -1em;
      }
    }
  }
}

@media (max-width: 1180px) {
  .hero {
    .orangeBtn {
      padding: 0.75em 0.5em;
    }
  }
}
@media (max-width: 1110px) {
  .hero {
    .world {
      height: 350px;
      img {
        margin-bottom: -1em;
      }
    }
  }
}
@media (max-width: 950px) {
  .hero {
    height: 100vh;

    .world {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: 0;
      left: 0;
      img {
        margin-bottom: -1em;
      }
    }

    .beeLogo {
      width: 600px;
    }
  }
}

// @media (max-height: 620px) {
//   .hero {
//     .world {
//       display: none !important;
//     }
//   }
// }

@media (max-width: 650px) {
  .hero {
    .beeLogo {
      display: none;
    }

    .mobileBeeLogo {
      display: block;
      width: 220px;
      margin: 3em auto 0;
    }
  }
}
