@import './../../../shared.scss';

.teachersContainer {
    display: flex;
    align-items: baseline;
    margin-top: 10em;
    margin-bottom: 10em;
    position: relative;
    z-index: 10;


    .text {
        text-align: left;


        a {
            @include btn(white, $main-blue--bright, 1.5em)
        }

    }

    .cardContainer {

        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        background-image: url('./../../../Assets/HomePage/blackSphere.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-position-x: 50%;
        padding: 3em 0em;

        .card {

            background-color: white;
            border-radius: 10px;
            width: 390px;
            height: 180px;
            margin: 0.75em;
            color: $main-blue;
            text-align: left;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            -webkit-box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
            -moz-box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);
            box-shadow: 0px 0px 30px -5px rgba(5, 95, 230, 0.5);

            &.goDown {
                margin-top: 90px;
            }

            &.goUp {
                margin-top: -4em;
            }

            &.pink {
                &:before {
                    background-color: $main-pink;
                }
            }

            &.purple {
                &:before {
                    background-color: $main-purple;
                }
            }

            &.green {
                &:before {
                    background-color: $main-green;
                }
            }

            .cardBody {
                display: flex;
                flex-direction: column;

                padding: 3em 0.5em 2em 1.5em;

                h3 {
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 26px;
                }

                p {
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 22px;
                }
            }


        }

        .card:before {
            content: '';
            height: 75%;
            width: 6px;
            background-color: $main-orange--bright;
            position: absolute;
            top: 12.5%;
            right: calc(100% - 6px);
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;

        }
    }

}

@media (max-width:1182px) {

    .teachersContainer {
        flex-direction: column;
        margin-top: 4em;

        .text {
            text-align: center;
            width: 100%;

        }

        .cardContainer {
            padding: 0em 0em 3em;
            background: none;
            background-color: $main-lightbluegrey;
            justify-content: center;
            margin-top: 0.5em;

            .card {
                width: 90%;
                height: 160px;
                justify-content: flex-start;

                .cardBody {
                    padding: 0em 1em 0 1.5em;

                    h3 {
                        font-size: 20px;
                    }

                    p {
                        margin: 0;
                    }


                }

                &.goUp {
                    margin-top: 0.75em;
                }

                &.goDown {
                    margin-top: 2em;
                }
            }
        }

    }

  }
  @media (max-width:400px){

    .teachersContainer{
      margin-top: 80px;
      
      .text {
        a{
          display: none !important;
        }
        h2{
          margin-bottom: 35px !important;
        }
      }
    }
  }