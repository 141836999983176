@import "./../../Parents.module.scss";

.journey {
  padding-top: 10em;
  padding-bottom: 2em;
  margin: 0 20px;

  .container {
    @include container();
    justify-content: space-between;
    flex-wrap: wrap;
    .titleContainer {
      h2 {
        margin-bottom: 2em;
      }
      .imgWrapper {
        @include borderShadowBox(2px);
        display: flex;
        justify-content: center;
        align-content: center;
        max-width: 580px;
        span {
          margin: 0.5em;
          width: fit-content;
        }
      }
    }
    .textContainer {
      margin-top: 10em;
      margin-left: 2em;
    }
  }
}

@media (max-width: 1180px) {
  .journey {
    padding-top: 2em;
    .container {
      justify-content: center;
      .titleContainer {
        text-align: center;
        h2 {
          max-width: 600px;
        }
      }
      .textContainer {
        margin-top: 2em;
        text-align: center;
      }
    }
  }
}

@media (max-width: 620px) {
  .journey {
    .container {
      justify-content: center;
      .titleContainer {
        text-align: center;
        h2 {
          font-size: 36px;
          line-height: 36px;
          margin-bottom: 0.75em;
        }
      }
      .textContainer {
        margin-top: 2em;
        margin-left: 0;
        text-align: center;
      }
    }
  }
}
