@import '../../../shared.scss';
@import './sharedModal.scss';

.loginModal {
    background-color: white;
    border-radius: 20px;
    box-shadow: 10px 10px 25px rgba(3, 8, 53, 0.1);
    padding: 4em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 970px;

    .modalHeader {
        display: flex;
        margin-bottom: 1.5em;

        h3 {
            margin-right: 1em;
            font-size: 30px;
            line-height: 42px;
        }

        
    }

    img.homeOf{
        width: 280px;
        object-fit: contain;
        position: relative;
        bottom: 40px;
    }

    .optionsContainer {
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-evenly;
        position: relative;

        .option {
            box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
            margin: 1em;
            border-radius: 20px;
            max-width: 350px;


            &:hover {
                box-shadow: 0px 20px 30px 5px rgba(6, 95, 229, 0.2);
            }

            a {
                padding: 3.75em 5.25em;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
            }

            h3 {
                font-size: 36px;
                line-height: 40px;
            }

            .imgWrapper {
                width: 82px;
            }
        }
    }
}

@media(max-width: 1250px) {
    .loginModal {
        padding: 3em;

        .optionsContainer {
            .option {
                max-width: 450px;
                a {
                    padding: 2.75em 4em;
                }
            }
        }
    }
}

@media(max-width: 1050px) {
    .loginModal {
        padding: 2em;

        .optionsContainer {
            .option {
                a {
                    padding: 1.75em 3em;
                }
            }
        }
    }
}

@media(max-width: 1050px) {
    .loginModal {
        padding: 2em;

        .modalHeader {
            flex-direction: column;
            margin-bottom: 1em;
        }

        .optionsContainer {
            .option {
                a {
                    padding: 1.75em 3em;
                }

                h3 {
                    font-size: 26px;
                    line-height: 30px;
                }
            }
        }
    }
}

@media(max-width: 850px) {
    .loginModal {
        padding: 1.5em;

        .modalHeader{
            margin-bottom: 30px;
            h3{
                font-size: 25px;
            }
            .imgWrapper{
                height: 30px;
                img{
                    width: 160px;
                    position: relative;
                    left: -10px;
                }
            }
        }

        .optionsContainer {
            flex-direction: column;
            justify-content: center;
            width: 70vw;

            .option {
                margin: 7px auto;
                width: 100%;
                
                a {
                    flex-direction: row;
                    padding: 1.75em 2.5em 1.75em 1.5em;;
                    box-sizing: border-box;
                    width: 100%;
                    justify-content: space-evenly;
                }

                .imgWrapper {
                    width: 50px;
                    margin-right: 10px;
                    img{
                        width: 50px;
                    }
                }
            }
        }
    }
}

@media(max-width: 490px) {

    .loginModal {


        .optionsContainer {

            .option {


                a {
                    padding: 1.5em;
                    flex-direction: row;
                }

                .imgWrapper {
                    width: 50px;
                }
            }
        }
    }
}