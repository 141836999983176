@import "../../../shared.scss";

.storiesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .groupWriting {
        background: linear-gradient(94.11deg, #f26283 0.33%, #fe875f 100.5%);
        border-radius: 20px;
        width: 708px;
        height: 352px;
        padding: 1.5em 2.25em;
        color: white;
        position: relative;
        margin-top: 10px;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        -moz-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);

        h2 {
            font-size: 35px;
            line-height: 49px;
            color: white;
        }

        p {
            font-size: 18px;
            line-height: 26px;
            color: rgba(255, 255, 255, 0.8);
            max-width: 456px;
        }

        a {
            @include btn(white, $main-blue--bright, 2em);
        }

        .imgWrapper {
            position: absolute;
            width: 280px;
            top: calc(100% - 269px);
            left: calc(100% - 280px);
        }
    }

    .bigButton {
        @include btn(white, $main-blue--bright, 2em);

        margin: 2em auto 0;
    }

    .storyStarts {
        width: 308px;
        height: 352px;
        border-radius: 20px;
        margin-top: 10px;
        padding: 1.5em 2.25em;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: white;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        -moz-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);

        p {
            font-weight: bold;
            font-size: 26px;
            line-height: 26px;
            color: $main-black;
            width: 80%;
        }

        .imgWrapper {
            width: 160px;
            height: 160px;
            text-align: center;
            border-radius: 100px;
            background-color: #00a1b1;

            img {
                width: 123px;
                height: 183px;
                margin-top: -10px;
            }
        }
    }

    .whatStory {
        background-color: $main-blue;
        color: white;
        width: 100%;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        -moz-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        padding: 1.5em 2.25em;
        margin: 2em 0em 1em;
        border-radius: 20px;
        display: flex;

        // flex-wrap: wrap;

        .imgWrapper {
            width: 230px;
        }

        .bodyStory {
            margin-left: 3em;

            .bookContainer {
                display: flex;
                flex-wrap: wrap;
                margin-top: 2em;
                justify-content: space-evenly;

                .chapter {
                    width: 190px;
                    margin: 0px 0.5em;

                    h4 {
                        font-size: 18px;
                        line-height: 24px;
                        margin-bottom: 0.5em;
                    }
                }
            }
        }
    }

    .letsTry {
        -webkit-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        -moz-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        text-align: center;
        padding: 1.5em 2.25em;
        margin-bottom: 2em;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            font-size: 35px;
            line-height: 35px;
            margin: 1em;
        }

        a {
            @include btn(white, $main-blue--bright, 2em);
        }

        .imgContainer {
            display: flex;
            flex-wrap: wrap;

            .imgWrapper {
                width: 48%;
                padding: 0 0.5em;
                margin: 0 auto;
            }
        }
    }
}

.writers {
    color: $main-blue;
    border-radius: 20px;
    padding: 2.75em 2.25em;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
    -moz-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
    box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
    margin-bottom: 2em;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    margin-top: 2em;

    h3 {
        font-size: 35px;
        line-height: 35px;
    }

    p {
        max-width: 800px;
        font-size: 20px;
        line-height: 30px;
    }

    .carouselProvider {
        width: 750px;
        position: relative;

        .last {
            position: absolute;
            top: 48%;
            right: 105%;
        }

        .next {
            position: absolute;
            top: 48%;
            left: 105%;
        }

        img {
            width: 95%;
        }
    }

    .mobileCarouselProvider {
        display: none;
        width: 315px;
    }
}

@media (max-width: 1195px) {
    .storiesContainer {
        .groupWriting {
            width: 100%;
            margin-right: 0;
        }

        .storyStarts {
            width: 100%;
        }

        .whatStory {
            flex-direction: column;
            text-align: center;
            padding: 1.5em 1.25em;

            .imgWrapper {
                margin: 0 auto;
            }

            .bodyStory {
                margin: 0;

                h3 {
                    font-size: 30px;
                    line-height: 30px;
                }
            }
        }
    }

    .writers {
        padding: 1.5em 1.25em;

        .carouselProvider {
            width: 600px;
        }
    }
}

@media (max-width: 1023px) {
    .writers {
        .carouselProvider {
            width: 500px;
        }
    }
}

@media (max-width: 950px) {
    .storiesContainer {
        .groupWriting {
            text-align: center;
            height: auto;

            p {
                margin: 0 auto;
            }

            .imgWrapper {
                position: static;
                margin: 0 auto;
                margin-top: 1em;
                width: 200px;
            }

            a {
                margin-top: -0.38em;
            }
        }
    }
}

@media (max-width: 640px) {
    .writers {
        padding: 1.5em 1.25em;

        .carouselProvider {
            display: none;
        }

        .mobileCarouselProvider {
            display: block;

            .buttons {
                display: flex;
                justify-content: space-evenly;
                margin-top: 1em;

                img {
                    width: 32px;
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .storiesContainer {
        .groupWriting {
            h2 {
                font-size: 26px;
                line-height: 30px;
            }

            p {
                margin: 0 auto;
                font-size: 16px;
                line-height: 20px;
            }
        }

        .storyStarts {
            display: none;
        }

        .letsTry {
            h3 {
                font-size: 30px;
                line-height: 30px;
                margin: 0.25em;
            }

            .imgContainer {
                .imgWrapper {
                    width: auto;
                }
            }
        }
    }
    .writers {
        h3 {
            font-size: 30px;
            line-height: 30px;
        }

        p {
            font-size: 16px;
            line-height: 20px;
        }

        .mobileCarouselProvider {
            width: 250px;
        }
    }
}
