@import "./../../Parents.module.scss";

$ease: ease;
$duration: 1500ms;
$bg1: #eb4d30;
$bg2: #64c4ca;
$bg3: #1e1e22;
$bg4: #0b435a;
$white: #fff;

.interactive {
  padding: 5em 0em;
}
.blueBtn{
  @include btn(white,$main-blue--bright,2em);
  margin-top:3em
}

.container {
  @include container();
  justify-content: space-between;
  .cardContainer {
    max-width: 400px;
    .optionCard {
      @include borderShadowBox(1em);
      padding: 1.5em;
      width: 332px;
      height: 92px;
      margin: 1em;
      transition: all 0.5s ease;
      position: relative;
      h3 {
        font-weight: 600;
        font-size: 26px;
        line-height: 26px;
        color: #2b4e7b;
        margin-bottom: 10px;
      }
      p {
        margin: 0;
        font-size: 16px;
        line-height: 20px;
        color: #2b4e7b;
        max-width: 330px;
      }
      &:before {
        content: "";
        height: 75%;
        width: 0px;
        background-color: $main-orange--bright;
        position: absolute;
        top: 12.5%;
        left: calc(100% - 6px);
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        transition: all 0.5s ease;
        // &.active{
        //   width: 6px;
        // }
      }
      &.active {
        width: 400px;
        &:before {
          width: 6px;
        }
      }
    }
  }
  .imageShowcase {
    width: 600px;
    position: relative;

    .img {
      position: absolute;
      opacity: 0;
      transition: all 0.5s ease;
      top: 30px;
      &.active {
        opacity: 1;
      }
    }
    .connectImg {
      width: 80%;
    }
  }
}

.piecesMobileContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 0em auto;
  color: $main-blue;

  .text {
    margin-bottom: 3em;
    margin: 0 1em 2em;

    a {
      @include btn(white, $main-blue--bright, 1.5em);
    }
  }

  .piece {
    margin: 0 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    -webkit-box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
    -moz-box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
    box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
    background-color: white;
    padding: 1.5em;
    margin-bottom: 1em;
    position: relative;
    transition: all 1.5s ease;
    text-align: left;

    &.active {
      justify-content: flex-start;
      padding-bottom: 280px;

      img {
        // margin-top: 1.5em;

        left: 30%;
        display: block;
        opacity: 1;
        animation: fadeInFromNone 2s ease-out;
      }
    }

    @keyframes fadeInFromNone {
      0% {
        display: none;
        opacity: 0;
      }

      100% {
        display: block;
        opacity: 1;
        animation: fadeInFromNone 0.5s ease-out;
      }
    }

    p {
      font-size: 16px;
    }

    .plusToMinus {
      width: 20px;
      height: 20px;
      cursor: pointer;
      transition: transform $ease $duration;
      position: absolute;
      top: 20px;

      left: calc(100% - 40px);

      &:before,
      &:after {
        content: "";
        position: absolute;
        background-color: $main-blue;
        border-radius: 2px;
      }

      &:before {
        left: 50%;
        margin-left: -1px;
        width: 2px;
        height: 20px;
      }

      &:after {
        top: 50%;
        margin-top: -2px;
        height: 2px;
        width: 20px;
        transition: transform $ease $duration;
      }

      &.minus {
        transform: rotate(-90deg);

        &:after {
          transform: rotate(-90deg);
        }
      }
    }

    img {
      position: absolute;
      display: none;
      opacity: 0;
      width: 350px;
    }
  }
}

.storeImg{
  cursor:pointer
}

@media (max-width: 550px) {
  .piecesMobileContainer {
    .piece {
      &.active {
        img {
          left: 10%;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .piecesMobileContainer {
    .piece {
      &.active {
        img {
          left: 5%;
          width: 90%;
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .piecesMobileContainer {
    .piece {
      &.active {
        img {
          margin-top: 2em;
        }
      }
    }
  }
}
