@import '../../../shared.scss';


.assignmentsContainer {
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;

    .assignmentTool {
        // width: 100%;
        background: linear-gradient(180deg, #065FE5 0%, #8E00F0 100%);
        border-radius: 20px;
        padding: 1.75em 2.25em;
        color: white;
        position: relative;
        margin-top: 10px;
        margin-bottom: 2em;
        width: 100%;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        -moz-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);

        h2 {
            color: white;
            font-size: 35px;
            line-height: 54px;
            max-width: 80%;
        }

        a {
            @include btn(white, $main-orange--bright, 2em)
        }



        .imgWrapper {
            position: absolute;
            width: 300px;
            top: calc(100% - 220px);
            left: calc(100% - 380px)
        }
    }

    .flexibleTool {
        color: $main-blue;
        border-radius: 20px;
        padding: 2.75em 2.25em;
        -webkit-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        -moz-box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        box-shadow: 0px 0px 30px 0px rgba(180, 207, 247, 1);
        margin-bottom: 2em;


        h3 {
            font-size: 30px;
            line-height: 40px;
        }

        a {
            @include btn(white, $main-orange--bright, 2em)
        }

        .listCarousel {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            .shortList {
                font-size: 20px;
                line-height: 36px;
                margin-top: 2em;
                max-width: 280px;

                li {
                    margin: 0.5em 0;
                }
            }

            .longList {
                font-size: 16px;
                line-height: 24px;
                max-width: 260px;
                margin-top: 2em;

                li {
                    margin: 0.5em 0;
                }
            }

            .carouselProvider {
                width: 800px;
            }

            .buttons {
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    display: inline-block;
                    font-size: 25px;
                    line-height: 26px;
                    padding: 0 2em;
                    width: 210px;
                    // font-weight: 400;
                    color: rgba(7, 36, 72, 0.5);
                    transition: all 0.5s ease;
                }

                .correctClick {
                    border-radius: 100px;
                    width: 80px;
                    height: 80px;

                    .carouselBtn {
                        margin: 0
                    }
                }
            }
        }


    }
}

@media (max-width:1213px) {
    .flexibleTool {
        text-align: center;

        .listCarousel {
            ul {
                text-align: left;
            }
        }



    }
}

@media (max-width:1130px) {
    .assignmentsContainer {
        .assignmentTool {
            text-align: center;

            .imgWrapper {
                position: static;
                margin: 0 auto;
                margin-top: 1em;

            }

            h2 {
                max-width: none;

            }
        }

        .flexibleTool {
            .listCarousel {


                .carouselProvider {
                    width: 600px;
                }
            }

        }
    }
}

@media (max-width:950px) {
    .assignmentsContainer {
        .assignmentTool {
            height: auto;

            p {
                display: none;

            }

            h2 {

                font-size: 35px;
                line-height: 30px;
            }

            .imgWrapper {

                width: 245px;
            }

        }

        .flexibleTool {
            .listCarousel {
                .list {
                    margin-top: 0em;
                }

                .carouselProvider {
                    width: 600px;
                }
            }

        }
    }

}

@media (max-width:740px) {

    .assignmentsContainer {
        .flexibleTool {
            .listCarousel {
                .carouselProvider {
                    width: 400px;
                }
            }

        }
    }
}

@media (max-width:540px) {
    .assignmentsContainer {
        .flexibleTool {
            h3 {
                font-size: 26px;
                line-height: 30px;
            }

            li {
                font-size: 16px;
                line-height: 18px;
            }

            .listCarousel {
                .carouselProvider {
                    width: 300px;

                    .centerCarousel {
                        .buttons {
                            justify-content: space-evenly;

                            p {
                                font-size: 20px;
                                line-height: 26px;
                                padding: 0;
                            }
                        }
                    }
                }
            }

        }
    }
}


@media (max-width:430px) {
    .assignmentsContainer {
        .assignmentTool {
            padding: 1.5em 1.25em;

            h2 {

                font-size: 26px;
                line-height: 30px;
            }
        }

        .flexibleTool {
            padding: 1em 0.25em;

            .listCarousel {
                .carouselProvider {
                    width: 280px;


                }
            }

        }
    }
}