@import '../../../shared.scss';


.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  height: 290px;
  width: 680px;
  position: relative;
  background-color: white;
  // visibility: hidden;

  img {
    height: 60px !important;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 26px;
    font-weight: 500;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    margin: 15px 0 0 0;

    &:last-of-type {
      margin-bottom: 60px;
    }
  }

  .closeBtn {
    @include solidButton(white, $main-blue--bright, 15px, 35px, 150px);
    position: absolute;
    bottom: 25px;
  }

  &.show {
    visibility: visible;
  }

  @media (max-width:1100px){
    width: 75%;
  }
}