.modal {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.6);
    z-index: 100;

    &.show {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .closingBtn {
        cursor: pointer;
        position: absolute;
        top: 5%;
        right: 5%;
        &.outer {
            top: -5px;
            right: -10px;
        }
    }

    .pricingModal {
        background-color: white;
        border-radius: 20px;
        max-width: 780px;
        margin: 1em;
        position: relative;
    }
}

@media (max-width: 520px) {
    .pricingModal {
        main {
            padding: 1.5em;
        }
    }
}
