@import '../../../../shared.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:100%;
  max-width: initial;

  .inner{
    width:100%;
  }

  .loading{
    margin-top: 50px;
    
    h4{
      text-align: center;
      font-size: 15px;
      color: $main-blue;
    }

    img{
      height: 60px;
      width: 60px;
      object-fit: contain;
    }
  }



  .back {
    position: relative;
    top: -15px;
    left: 5px;
    color: $main-blue;
    font-weight: 700;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

}