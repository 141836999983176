@import '../../shared.scss';


.HomePageContainer {
    width: 1200px;
    margin-left: 10px;
    margin-right: 10px;
}

.bigScreen{
    position: absolute;
}

.HomePageContainerNoAir {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.centerContainer {
    display: flex;
    justify-content: center;
}

.whiteWave {
    background-image: url('../../Assets/Backgrounds/hpWhiteWaveBg.png');
    margin-top: -15em;
    padding-top: 8em;
    padding-bottom: 22em;
    background-repeat: no-repeat;

}

.whiteWaveUnder {
    background-image: url('../../Assets/Backgrounds/rotatedWhiteWave.svg');
    background-repeat: no-repeat;
    // background-color: lightcoral;
    margin-top: -18em;
    z-index: 2;
}

.blueWave {
    background-image: url('../../Assets/Backgrounds/trueWhiteWave.svg');
    background-repeat: no-repeat;
    margin-top: -9em;
    padding-bottom: 10em;
    background-color: $main-lightbluegrey
}

.downGreyWave {
    background-image: url('../../Assets/Backgrounds/downGreyWave.svg');
    background-repeat: no-repeat;


    ;


}