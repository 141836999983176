@import './../../../shared.scss';

.wrapper {
    padding: 1.25em 0 0.75em;
    display: flex;
    align-items: center;

    .arrow {
        transform: rotate(0);
        left: 0;
        padding: 0 0.5em;
        cursor:pointer;

        &:before,
        &:after {

            background-color: transparent;
            width: 2.5px;
            height: 10px;
            display: inline-block;
            position: absolute;
            border-bottom: 12px solid white;
            top: 0;
            left: 0;
            transform: rotate(0);
        }

        &:before {

            transform: rotate(-135deg);
        }

        &:after {

            transform: rotate(135deg);
        }
    }

    &.active {

        .arrow {

            transform: rotate(0);
            transform: translate(0, -6px);

            &:before {
                transform: rotate(-45deg)
            }

            &:after {
                transform: rotate(45deg)
            }
        }
    }
}


.arrow {

    width: 13px;
    height: 13px;
    display: inline-block;
    position: relative;
    top: -2px;
    margin-left: 20px;
    transition: 0.4s ease;
    margin-top: 2px;
    text-align: left;
    transform: rotate(45deg);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:before,
    &:after {
        position: absolute;
        content: '';
        display: inline-block;
        width: 12px;
        height: 3px;
        background-color: #fff;
        transition: 0.4s ease;
    }

    &:after {
        position: absolute;
        transform: rotate(90deg);
        top: -5px;
        left: 5px;
    }
}

.dropdown {
    height: 0;
    transition: all 1s ease;
    padding: 0em 1.5em;

    a {
        color: rgba(255, 255, 255, 0.7);
        padding: 0.25em 0em;
        display: block;
        opacity: 0;
        transition: all 1s ease;
        pointer-events: none;
        cursor: default;
        // height: 0;
    }

    &.active {
        height: 60px;
        padding: 0em 1.5em;

        a {
            pointer-events: auto;
            cursor: pointer;
            opacity: 1;
            font-size: 14px;
        }
    }
}

@media (max-height:700px) {
    .wrapper {
        padding: 0.75em 0 0.25em;

    }
}