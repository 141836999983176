@import '../../../shared.scss';

.consistency {
    color: $main-blue;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 9em 0em;

    a {
        @include btn(white, $main-blue--bright, 1.5em)
    }

    div {
        max-width: 480px;
    }

    .consistencyPhoto {
        margin-left: -30px;
    }

    .mobilePhoto {
        display: none;
    }


}

@media (max-width:950px) {

    .consistency {
        margin: 3em 0;

        .mobilePhoto {
            display: block;
        }

        .consistencyPhoto {
            display: none;
        }

        .rotatedPhoto {
            display: none;
        }

        div {
            max-width: auto;
            text-align: center;

            h2 {

                font-size: 40px;
                line-height: 40px;
            }
        }
    }
}