@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:wght@1;500;700;900&display=swap');

$main-black:rgb(51, 51, 51);
$main-blue: rgb(43, 78, 123);
$main-blue--bright: rgb(6, 95, 229);
$main-blue--light: rgb(47, 124, 160);
$main-orange: rgb(241, 128, 86);
$main-orange--bright: rgb(255, 168, 38);
$main-green: rgb(151, 211, 59);
$main-pink: rgb(238, 98, 131);
$main-yellow: rgb(255, 168, 38);
$main-purple: rgb(142, 43, 240);
$main-purple--dark: #4E406E;
$main-grey: rgb(133, 133, 133); //858585
$main-grey--bee:#4B4A4A;
$main-bluegrey: rgb(240, 244, 246);
$main-lightbluegrey:rgb(243, 247, 253);
$grey-unselected: rgb(191, 202, 202);// ~ cacaca
$grey: rgb(225, 229, 231);
$main-brown: #8b5e1b;

@mixin btn($color, $bg, $padding) {
  cursor: pointer;
  background: $bg;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: $color;
  text-align: center;
  padding: 0.75em $padding;
  margin: 0.5em 0em;
  display: inline-block;
  border: 1.5px solid $bg;
  transition: all 0.5s ease;

  &:hover {
    background-color: $color;
    color: $bg;
    border: 1.5px solid $bg;
  }
}

@mixin shadow($blur,$color){
  box-shadow: 0 0 $blur -5px $color;
}


body {
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: 'Sofia Sans', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $main-black;


}

.mainSwitchDiv{
  margin-top: 80px;

  @media (max-width:950px){
    margin-top: 50px;
  
  }
}

input {
  font-family: 'Sofia Sans', sans-serif;
}

input:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
}

h1 {
  font-weight: bold;
  margin: 3px 0;
  font-size: 55px;
  line-height: 50px;
}

h2 {
  font-weight: bold;
  margin: 3px 0;
  font-size: 50px;
  line-height: 45px;
  justify-content: space-between;
}

h3 {
  margin: 3px 0;
  font-weight: bold;
  font-size: 26px;
  line-height: 26px;
}

h4 {
  margin: 3px 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
}

img {
  width: 100%;
}

button:focus {
  outline: none;
}

.App {
  background-color: white;
  // height: 100vh;
  position: relative;
  overflow-x: hidden;
}

//Helpers

.textWhite {
  color: white !important
}

.textGray {
  color: $main-black;
}

.whiteBg {
  background-color: white;
}

.lightbluegreyBg {
  background-color: $main-lightbluegrey
}

.orangeBrightBg {
  background-color: $main-orange--bright;
}

.blueBg {
  background-color: $main-blue;
}

.lightblueBg {
  background-color: $main-blue--light;
}

.btn {
  background: $main-blue--bright;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: white;
  text-align: center;
  padding: 0.75em 2em;
  margin: 0.5em 0em;
  display: inline-block;

}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

.align {
  // justify-content: center;
  align-items: center;
}



@mixin solidButton ($color, $bg, $font-size, $height, $width) {
  height:$height;
  width:$width;
  color:$color;
  font-size: $font-size;
  // background: url($imgUrl);

  background-color: $bg;
  border:1px solid $bg;
  text-align: center;
  line-height: $height;
  cursor: pointer;
  transition: .3s all ease-out;
  border-radius: 8px;
  position: relative;
  background-repeat: no-repeat;
  
  &:hover{
    // background: url($hovImgUrl);
    color:$bg;
    background-color: $color;
    border-color: $bg;
    background-repeat: no-repeat;
    }
    
}


@mixin mainButton ($color, $bg, $font-size, $height, $width) {
  height:$height;
  width:$width;
  color:$color;
  font-size: $font-size;
  // background: url($imgUrl);
  background-color: $bg;
  border:1px solid $color;
  text-align: center;
  line-height: $height;
  cursor: pointer;
  transition: .3s all ease-out;
  border-radius: 8px;
  position: relative;
  background-repeat: no-repeat;
  
  &:hover{
    color:$bg;
    // background: url($hovImgUrl);
    background-color: $color;
    background-repeat: no-repeat;
    }
    
}


.shake{
  -webkit-animation: shake .4s ease-in-out 1;
  -moz-animation: shake .4s ease-in-out 1;
  -ms-animation: shake .4s ease-in-out 1;
  -o-animation: shake .4s ease-in-out 1;
  animation: shake .4s ease-in-out 1;
}

.shakeSlow{
  -webkit-animation: shake .5s ease-in-out 1;
  -moz-animation: shake .5s ease-in-out 1;
  -ms-animation: shake .5s ease-in-out 1;
  -o-animation: shake .5s ease-in-out 1;
  animation: shake .5s ease-in-out 1;
}

@keyframes shake{
  0%{
    transform:translateX(0)
  }
  20%{
    transform:translateX(5px)
  }
  40%{
    transform:translateX(-5px)
  }
  60%{
    transform:translateX(5px)
  }
  80%{
    transform:translateX(-5px)
  }
  100%{
    transform:translateX(0)
  }
}

.shakeSm{
  -webkit-animation: shakeSm .4s ease-in-out 1;
  -moz-animation: shakeSm .4s ease-in-out 1;
  -ms-animation: shakeSm .4s ease-in-out 1;
  -o-animation: shakeSm .4s ease-in-out 1;
  animation: shakeSm .4s ease-in-out 1;
}

@keyframes shakeSm{
  0%{
    transform:translateX(0)
  }
  20%{
    transform:translateX(2px)
  }
  40%{
    transform:translateX(-2px)
  }
  60%{
    transform:translateX(2px)
  }
  80%{
    transform:translateX(-2px)
  }
  100%{
    transform:translateX(0)
  }
}

//Shared Sections


.blueBoardBg {
  background-size: cover;
  background-image: linear-gradient(rgba(7, 124, 160, 0.8), rgba(7, 124, 160, 0.8)), url('./Assets/Backgrounds/whiteboardBg.png');
  background-repeat: no-repeat;
}

@media (max-width:950px) {
  h2 {
    font-size: 40px;
    line-height: 40px;
  }

  h3 {
    font-size: 20px;
    line-height: 26px;
  }

  .mobile {
    display: none;
  }
}



.bookPurchaseCardDetails{
  overflow: hidden;
  height: 115px;
  width: 100%;
  position: relative;
  margin-top: 20px !important;

  .StripeElement{
    padding: 12px;
    border:1px solid #cacaca;
    border-radius: 5px;
    margin:3px 0;
  }
}


  // country picker

  select[name="rcrs-region"],
  select[name="rcrs-country"]{
    height: 35px;
    width: 600px;
    border-radius: 5px;
    border: 1px solid #cacaca;
    box-sizing: border-box;
    padding: 0 10px;
    display: block;
    margin:6px 7px 6px 0;
    cursor: pointer;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background:url('../src/Assets/Icons/icon-chevron--down--black.png') no-repeat right 10px center !important;
    // background: url('./assets/icon-chevron--down--black.png') no-repeat right 10px center !important;
    background-size: 20px !important;

    &:focus{
      outline: none;
    }

    @media (max-width:1250px){
      width: 100%;
    }
  }

  .errorBorder{
    border:1px solid red !important;
  }

  // STRIPE
 
  .StripeElement{
    padding: 12px;
    border:1px solid #cacaca;
    border-radius: 5px;
    margin:3px 0;
  }

  // carousel

  .slick-slider{
    width:480px;
    height: 300px;
    border-radius: 15px;

    .carousel-item{
      position: relative;
      border-radius: 15px;
      overflow: hidden;

      &::before{
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        border-radius: 15px;
        background: -moz-linear-gradient( 137deg , #0ca6de 0%, #2e65ae 100%);
        background: -webkit-linear-gradient( 137deg , #0ca6de 0%, #2e65ae 100%);
        background: linear-gradient( 137deg , #0ca6de 0%, #2e65ae 100%);
        // mix-blend-mode: overlay;
        opacity: .4;
  
      }

      img.carousel-play{
        height: 80px;
        width: 80px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -45%);
        cursor:pointer;
        z-index: 100;
        transition: all .3s ease-in;
      }

      img.carousel-img{
        width:480px;
        height: 300px;
        object-fit: cover;
        border-radius: 15px;
      }

      
    }
  }

  .slick-dots{
    position: absolute !important;
    left: 50%;
    transform: translateX(-50%);
    bottom:-30px;


    ul{
      margin: 0 auto !important;
      width: 25px;
      // border:1px solid chartreuse;
      display: flex !important;
      padding:0;
    }

    li button{
      height:10px !important;
      width: 10px !important;
    }

    li button:before {
      font-family: 'slick';
      font-size: 6px;
      line-height: 20px;
      position: absolute;
      top: 5px;
      left: 0;
      width: 10px !important;
      min-width: 10px !important;
      max-width: 10px !important;
      height: 10px !important;
      min-height: 10px !important;
      max-height: 10px !important;
      content: '' !important;
      text-align: center;
      opacity: .2 !important;
      background-color: $main-blue--bright !important;
      border-radius: 50% !important;
    }
    li.slick-active button:before {
      opacity: 1 !important;
    }

  } 

  // gradient classes

  .darkBlueGradient{
    background: rgb(7,124,160);
    background: linear-gradient(117deg, rgba(7,124,160,1) 0%, rgba(43,78,123,1) 78%);
  }

  .pinkGradient{
    background: rgb(242,98,131);
    background: linear-gradient(117deg, rgba(242,98,131,1) 0%, rgba(254,135,95,1) 78%);
  }

  .lightBlueGradient{
    background: rgb(12,167,216);
    background: linear-gradient(117deg, rgba(12,167,216,1) 0%, rgba(46,102,174,1) 78%);
  }

  .orangeGradient{
    background: rgb(255,168,38);
    background: linear-gradient(117deg, rgba(255,168,38,1) 0%, rgba(255,116,38,1) 78%);
  }

  .textXs > div > h3{ 
    font-size: 30px; 
    line-height:40px; 
    text-align : left;

    @media (max-width:950px){
      font-size: 25px;
      line-height: 30px;
    }
  }
  .textS > div > h3{ 
    font-size: 40px; 
    line-height:50px; 
    text-align : left;

    @media (max-width:950px){
      font-size: 35px;
      line-height: 40px;
    }
  }
  .textM > div > h3{ 
    font-size: 45px; 
    line-height:55px; 
    text-align : left;
  }
  .textLg > div > h3{ 
    font-size: 60px; 
    line-height:70px; 
    text-align : left;
  }