@import '../../shared.scss';

.person {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #333333;
    margin-bottom: 7em;
    border-radius: 10px;
    padding-bottom: 1em;
    width: 380px;
    -webkit-box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
    -moz-box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
    box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);

    .imgWrapper {
        width: 200px;
        height: 200px;
        margin-top: -80px;
        border-radius: 150px;
        align-self: center;
        -webkit-box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
        -moz-box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
        box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
    }

    div {
        padding: 0 2em;
    }

    h3 {
        font-weight: bold;
        font-size: 26px;
        line-height: 16px;
        padding-top: 0.75em;
    }

    p {
        margin: 0.75em 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 16px;
    }

    div.link {
        padding-bottom: 1em;
        float: right;
        cursor: pointer;
    }
}