@import "./../../WritingBee.module.scss";

.timeline {
  padding-bottom: 2em;
  flex-direction: column;

  .cardGrid {
    margin-top: 3em;
    display: flex;
    // width: 1200px;
    flex-wrap: wrap;

    .coolDiv {
      display: flex;
      flex-direction: column;
      flex-grow: 2;
    }

    .basicCard {
      background-color: white;
      border-radius: 20px;
      margin: 0.5em;
      padding: 1.25em;
      padding-bottom: 2em;
      max-width: 240px;
      @include shadow(25px, rgba(0, 0, 0, 0.3));
      figure {
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        width: 180px;
      }
      p {
        text-align: left;
        margin-bottom: 0;
      }
      h3 {
        margin: 0.5em 0;
      }
      &:first-child {
        max-width: 830px;
        flex-grow: 2;
        padding: 1.5em;
        p {
          margin: 0;
          padding: 1.25em;
        }
      }
      &:last-child {
        img {
          width: 110px;
        }
      }
    }

    .btnContainer {
      display: flex;
      margin: 0.5em 0.75em 0;
      justify-content: center;
      .blueBtn {
        font-size: 14px;
        
      }
      .aquamarineBtn {
        font-size: 14px;
      
      }
    }
  }
}

@media (max-width: 1220px) {
  .timeline {
    .cardGrid {
      .coolDiv {
        width: 100%;
      }
      .basicCard {
        max-width: stretch;
        margin: 0.5em 1em;
        &:first-child {
          max-width: stretch;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .timeline {
    padding-top: 5em;

    .timelineImg {
      display: none;
    }

    .btnContainer {
      .blueBtn {
        margin: 0.5em;
      }
      .aquamarineBtn {
        margin: 0.5em;
      }
    }
  }
}
