@import "./../../shared.scss";

@mixin borderShadowBox($padding) {
  border-radius: 20px;
  background-color: white;
  box-shadow: -5px -5px 25px rgba(6, 95, 229, 0.2), 5px 5px 25px rgba(6, 95, 229, 0.2);
  padding: $padding;
}

@mixin container() {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
  color: $main-blue;
  h2 {
    max-width: 550px;
    font-weight: 600;
    font-size: 40px;
    line-height: 40px;
    color: $main-blue;
    letter-spacing: 1px;
  }

  p {
    max-width: 490px;
    font-size: 18px;
    line-height: 26px;
    span {
      font-weight: bold;
    }
  }
}
