@import '../../../../shared.scss';

.tool {
    width: 380px;
    height: 280px;
    color: $main-blue;
    text-align: left;
    background-color: white;
    border-radius: 10px;
    margin: 2em 10px;
    -webkit-box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
    -moz-box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
    box-shadow: 0px 10px 30px -5px rgba(5, 95, 230, 0.2);
    padding: 6em 2em 2em;
    position: relative;
    transition: height 0.2s ease-in;

    .toolBody {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h3 {
            margin: 0.5em 0em
        }

        a {
            @include btn(white, $main-blue--bright, 2em);
            transition: opacity 0.2s ease-in;
            opacity: 0;
        }

        p {
            height: 190px;
        }
    }

    .imgWrapper {
        width: 120px;
        height: 120px;
        border-radius: 100px;
        position: absolute;
        top: -2em;
        display: flex;
        justify-content: center;
        align-items: center;

        

        &.orange {
            background-color: $main-orange;

            img {
                width: 65%;
            }
        }

        &.orangeBright {
            background-color: $main-orange--bright;

            img {
                width: 85%;
            }
        }

        &.blue {
            background-color: $main-blue--light;

            img {
                width: 85%;
            }
        }
    }

    .lazySpan{
        text-align: center;
    }

    .squareWrapper {
        width: 160px;
        height: 120px;
        border-radius: 10px;
        position: absolute;
        top: -2em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;

        &.orange {
            background-color: $main-orange;

            img {
                width: 65%;
            }
        }

        &.orangeBright {
            background-color: $main-orange--bright;

            img {
                width: 85%;
            }
        }

        &.blue {
            background-color: $main-blue--light;

            img {
                width: 85%;
            }
        }
    }
}

.long {
    line-height: 24px;
}

.tool:hover {
    -webkit-box-shadow: 0px 20px 30px 5px rgba(6, 95, 229, 0.2);
    -moz-box-shadow: 0px 20px 30px 5px rgba(6, 95, 229, 0.2);
    box-shadow: 0px 20px 30px 5px rgba(6, 95, 229, 0.2);
    height: 310px;

    a {
        transition: opacity 0.2s ease-in;
        opacity: 1;
    }
}

@media (max-width:1150px) {
    .tool {
        max-width: 300px;

        margin: 0 auto;

        .toolBody {

            a {
                opacity: 1;
            }
        }
    }


}

@media (max-width:380px) {
    .tool {

        max-width: 200px;
    }

    .long {
        line-height: 18px;
    }
}