@import '../../../shared.scss';

.realData {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 15em 0em 12em;

    .data {
        display: flex;

        p {
            font-weight: bold;
            font-size: 50px;
            line-height: 50px;
            margin: 10px 0.5em
        }

        span {
            font-weight: bold;
            font-size: 18px;
            line-height: 16px;
            color: #858585;
        }
    }

    img {
        width: 70px;
    }
}

@media (max-width:1300px) {
    .realData {
        justify-content: space-evenly;
    }
}

@media (max-width:1010px) {
    .realData {
        .data {
            margin: 0 1.5em;
        }
    }
}