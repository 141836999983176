@import "./../../Parents.module.scss";

.parentCard {
  // margin: 5em 20px ;
  margin: -13em 20px 8em 20px;
  h2{
    // max-width: 550px;
    font-size: 40px;
    line-height: 40px;
    color: $main-blue;
    margin-bottom: 1em;
    letter-spacing: 1px;
  }
  .container {
    @include container();
    max-width: 980px;

    .actualCard {
      @include borderShadowBox(4em);
      width: 100%;
      background-image: url("./../../../../Assets/Parents/family.png");
      background-repeat: no-repeat;
      background-position: 0 100%;
      p{
        line-height: 30px;
      }
      .textContainer{
        float: right;
      }
    }
  }
}

@media (max-width: 1095px) {
  .parentCard{
    h2{
      display: none;
    }
  }
}

@media (max-width: 900px) {
  .parentCard{
    .container{
      .actualCard{
        padding: 1.5em;
        display: flex;
        justify-content: center;
        padding-bottom: 250px;
        background-size: 200px;
        
      }
    }
    h2{
      display: none;
    }
  }
}
