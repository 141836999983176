@import "./../../Parents.module.scss";

.writingEngagement {
  background-image: url("./../../../../Assets/Backgrounds/greyWave.svg");
  background-size: 100vw;
  padding: 5em 0em 25em;
  background-repeat: repeat-x;
  z-index: -1;
  .container {
    @include container();
    justify-content: space-between;
    flex-wrap: wrap;
    .bookImage {
      max-width: 480px;
    }
    .engagementImage {
      max-width: 580px;
      margin-top: -4em;
    }
    &:last-child{
      flex-wrap: wrap-reverse;
    }
  }
  .writing{
    margin-top: 5em;
    margin-bottom: 10em;
  }
}

.blueBtn{
  @include btn(white,$main-blue--bright,2em);
}
  
@media (max-width: 1180px) {
  .writingEngagement{
    background-image: none;
    background-color: $main-lightbluegrey;
    padding: 2em 0em 2em;

    .container{
      justify-content: center;
      margin: 0 20px;
      h2{
        text-align: center;
        font-size: 30px;
      }
      p{
        text-align: center;
      }
      .engagementImage{
        max-width: 300px;
      }
    }
    .writing{
      margin-top: 5em;
      margin-bottom: 5em;
    }
  }
}

