@import "../../shared.scss";

.toolsContainer {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1200px;
    text-align: left;
    color: $main-blue;
    padding: 1em;

    h1 {
        font-size: 50px;
        line-height: 70px;
        margin: 1em 0;
    }

    .selectorContainer {
        display: flex;
        margin-bottom: 1em;
        justify-content: flex-start;
        width: 100%;

        .selectorBtn {
            font-weight: bold;
            font-size: 18px;
            color: $main-grey;
            background-color: white;
            border-radius: 10px;
            padding: 0.5em 3em 0.5em 1em;
            position: relative;
            margin-right: 2em;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            svg {
                // width: 25px;
                // height: 25px;
                margin-right: 1em;
            }
        }

        .active {
            color: white;

            &.journals {
                background-color: $main-orange--bright;

                &:after {
                    border-top: 10px solid $main-orange--bright;
                }
            }

            &.assignments {
                background-color: $main-blue--light;

                &:after {
                    border-top: 10px solid $main-blue--light;
                }
            }

            &.stories {
                background-color: $main-orange;

                &:after {
                    border-top: 10px solid $main-orange;
                }
            }
        }

        .active:after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }
    }

    .responsiveCenter {
        display: flex;
        flex-direction: column;
    }
}

.title {
    color: white;
    background-image: url("./../../Assets/Tools/blueHero.png");
    text-align: left;
    padding: 5em 0em 25em;

    h1,
    h3 {
        max-width: 1200px;
        margin: 0 auto;
    }
}

.body {
    // background-color: lightcoral;
    background-image: url("./../../Assets//Backgrounds/whiteWave.svg");
    margin-top: -22em;
    height: 500px;

    .cardContainer {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;
        // flex-wrap: wrap;
    }
}

@media (max-width: 1195px) {
    .toolsContainer {
        margin: 0 5em;

        h1 {
            text-align: center;

            line-height: 50px;
            margin: 1em 0;
        }

        .responsiveCenter {
            align-items: center;
            justify-content: center;
        }

        .selectorContainer {
            justify-content: space-evenly;

            .selectorBtn {
                margin: 0 3px 0 3px;
            }
        }
    }
    .title {
        text-align: center;
    }
}

@media (max-width: 950px) {
    .toolsContainer {
        margin: 0 1em;

        .selectorContainer {
            .selectorBtn {
                padding: 0.5em 1em 0.5em 1em;
                min-width: 67px;

                svg {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 430px) {
    .toolsContainer {
        margin: 0 0em;

        .selectorContainer {
            .selectorBtn {
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 1087px) {
    .body {
        max-width: 100%;
        height: 1300px;

        .cardContainer {
            flex-wrap: wrap;
            height: 100%;
        }
    }
}
