@import './../Nav/Nav.module.scss';

.dropdown {
    box-shadow: 0px 0px 4px 0 rgba(180, 207, 247, 1);
    background-color: white;
    text-align: left;
    width: 150px;
    padding: 0.5em 1em;
    display: none;
    position: absolute;
    z-index: 4;
    top: 34px;
    left: -50%;
    border-radius: 3px;
    margin-top: 1em;


    &.show {
        display: flex;
        flex-direction: column;
    }

    a {
        color:$main-blue;
        padding: 0.5em
    }

    a:hover {
        color: rgba(43, 78, 123, 0.7)
    }
}

.test {
    color: $main-blue;
    position: relative;
}

.dropdown:before {
    content: '';
    // box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.14), -2px -2px 4px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 0px;
    right: calc(50% - 14px);
    width: 0;
    height: 0;
    box-sizing: border-box;

    border: 5px solid black;
    border-color: transparent transparent white white;

    transform-origin: 0 0;
    transform: rotate(135deg);

    box-shadow: -2px 2px 4px 0 rgba(180, 207, 247, 1);
}

.resetLi{
    text-align: left !important; 
    font-weight: normal !important;
    padding: 0 !important;
    list-style: none;
}